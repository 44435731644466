import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Text, Button } from 'grommet';

import { useMigrationEntityStatus } from '../../hooks';

import urlRoutes from '../../config/url-routes';

const disabledApproveButton = (envEntityStatus) => {
  const { migrationId, ...rest } = envEntityStatus;

  if(!migrationId) {
    return true;
  }

  return Object.keys(rest).some((item) => {
    // if there is any unmarked item (false) it returns tue, informing that the approve button must be disabled
    if(!envEntityStatus[item]) {
      return true;
    }

    return false;
  });
}

const MigrationActions = () => {
  const { push } = useHistory();
  const { migrationId } = useParams();

  const { envEntityStatus } = useMigrationEntityStatus(migrationId);

  const listOptions = [
    {
      label: 'Recusar migração',
      color: 'status-critical',
      action: () =>
        push({
          pathname: urlRoutes.changeMigrationRefuseStatusUrl.replace(/:migrationId/gi, migrationId),
        })
    },
    {
      label: 'Aprovar migração',
      disabled: disabledApproveButton(envEntityStatus),
      color: 'status-ok',
      action: () =>
        push({
          pathname: urlRoutes.changeMigrationApproveStatusUrl.replace(/:migrationId/gi, migrationId),
        })
    }
  ]

  return (
    <Box
      margin={{ bottom: 'medium' }}
      direction='row'
      gap='xsmall'
    >
      {listOptions.map((item, index) => (
        <Button
          key={index}
          primary
          disabled={item.disabled}
          title={item.disabled ? 'Marque como validado cada um dos itens ao lado para aprovar essa migração' : null}
          size='small'
          label={<Text color='white' size='small'>{item.label}</Text>}
          color={item.color}
          onClick={() => item.action()}
        />
      ))}
    </Box>
  )
}

export default MigrationActions;

import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockPropertyList from './fakes/property-listing';

const getPropertyList = (params) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockPropertyList), 500);
    });
  }

  const { migrationId, ...rest } = params;
  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'GET',
    url: `/migrations/${migrationId}/properties`,
    params: rest
  });
}

export default getPropertyList;


import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ResponsiveContext, Box, Text, Button } from 'grommet';

import { saveAs, formatNumber } from '../../commons';

const TableHeader = ({ title, totalItens, download }) => {
  const { migrationId } = useParams();
  const [loading, setLoading] = useState(false);

  const handlerDownload = useCallback(() => {
    setLoading(true);

    download.resource({
      migrationId
    })
      .then(({ data }) => saveAs(data, download.fileName))
      .finally(() => setLoading(false))
  });

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          background='light-1'
          direction='row'
          pad={{
            horizontal: size === 'small' ? 'large' : 'medium',
            vertical: size === 'small' ? 'medium' : 'small'
          }}
          round={{ size: 'small', corner: 'top' }}
          border={{
            side: 'bottom',
            color: 'light-3',
          }}
          align='center'
          justify='between'
        >
          <Text size='large'>{title}</Text>

          {download && totalItens > 0 ? (
            <Box
              direction='row'
              align='center'
              gap='small'
            >
              <Button
                primary
                size='small'
                label={loading ? 'Baixando...' : 'Baixar'}
                title={`Baixar a listagem dos ${formatNumber(totalItens)} registros`}
                color='light-5'
                disabled={loading}
                onClick={() => handlerDownload()}
              />

              <Text size='medium' color='dark-6'>
                {formatNumber(totalItens)} registro(s)
              </Text>
            </Box>
          ) : (
            <Text size='medium' color='dark-6'>
              {formatNumber(totalItens)} registro(s)
            </Text>
          )}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default TableHeader;

import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockEntityMigrationStatus from './fakes/entity-status';

const getEntityStatus = (migrationId) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockEntityMigrationStatus), 500);
    });
  }

  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'GET',
    url: `/migrations/${migrationId}/entities/status`,
  });
}

export default getEntityStatus;

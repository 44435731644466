import React, { useCallback, useEffect, useState } from 'react';
import { TextInput } from 'grommet';
import { formatNumber } from '../../commons';

const CurrencyInput = props => {
  const {
    value,
    name,
    onChange,
    ...rest
  } = props;

  const parseValue = useCallback((value) =>
    formatNumber(value, 'currency', 2)
  );

  const clearValue = useCallback((value) =>
    value
      .toString()
      .replace(/[^0-9]/g, '') / 100
  );

  const [formattedValue, setFormattedValue] = useState(parseValue(value));

  useEffect(() => {
    setFormattedValue(parseValue(value))
  }, [value]);

  return (
    <TextInput
      placeholder='R$ 0,00'
      value={formattedValue}
      onChange={({ target }) => {
        const cleanValue = clearValue(target.value);

        onChange(
          name,
          cleanValue,
        );
      }}
      {...rest}
    />
  )
}

export default CurrencyInput;

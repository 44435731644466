import React from 'react';
import { Box, Text, Anchor } from 'grommet';

const FileList = ({
  propName,
  propUrl,
  items
}) => (
  <Box
    pad={{ vertical: 'xsmall' }}
    fill='horizontal'
  >
    <Box
      round='xsmall'
      border={{
        color: 'light-4',
        side: 'all',
      }}
    >
      {items.map((item, index) => {
        return (
          <Box
            key={index}
            pad={{
              vertical: 'small',
              horizontal: 'medium'
            }}
            border={{
              color: 'light-4',
              side: 'bottom',
            }}
            direction='row'
            align='center'
            justify='between'
            gap='small'
          >
            <Text size='small' truncate={true}>{item[propName]}</Text>

            <Anchor
              label='visualizar'
              href={item[propUrl]}
              target='_blank'
            />
          </Box>
        )
      })}
    </Box>
  </Box>
)

export default FileList;

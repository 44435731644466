import React, { useContext } from 'react';
import { Intercom } from './components';

import { AppContext } from './context';

const Comunicator = () => {
  const { agencyInformations } = useContext(AppContext); 

  if (!process.env.REACT_APP_INTERCOM_ENABLE) {
    return null;
  }

  if (!agencyInformations || !agencyInformations.intercom) {
    return null;
  }

  return (
    <Intercom
      appID={process.env.REACT_APP_INTERCOM_ID}
      appSource={process.env.REACT_APP_INTERCOM_NAME}
      { ...agencyInformations.intercom }
    />
  );
};

export default Comunicator;

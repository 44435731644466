const resolveEnum = (list, searchBy) => {
  try {
    const actionResult = list.find(a => a.id.toString() === searchBy.toString());
    return actionResult.name || null;
  }
  catch(e) {
    return null;
  }
}

export default resolveEnum;

import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { getMe } from './api';
import { getItemStorage, deleteItemStorage } from './commons';

export const AppContext = createContext({
  logout: () => {},
  loadUserData: () => {},
  setLayerVisible: () => {},
  setLayerOpenId: () => {},
  setEnvEntityStatus: () => {},
  setForceReloadFetch: () => {},

  authenticated: null,
  agencyInformations: null,
  layerVisible: null,
  isOpenLayerDetail: null,
  layerOpenedId: null,
  envEntityStatus: null,
  forceReloadFetch: null,
});

const AppContextProvider = ({ children }) => {
  const [agencyInformations, setUserInfo] = useState(null);
  const [authenticated, setAuth] = useState(null);
  const [layerVisible, setLayerVisible] = useState(null);
  const [layerOpenedId, setLayerOpenId] = useState(null);
  const [forceReloadFetch, setForceReloadFetch] = useState(null);

  const [envEntityStatus, setEnvEntityStatus] = useState({
    brokers: false,
    customers: false,
    enterprises: false,
    properties: false,
    migrationId: null,
  });

  const reloadFetch = useCallback(() => {
    setForceReloadFetch(new Date());
  }, []); // Sem dependências, pois `setForceReloadFetch` é estável

  const logout = useCallback(() => {
    deleteItemStorage(process.env.REACT_APP_TOKEN_AUTH_NAME);
    setUserInfo(null);
    setAuth(null);
  }, []); // Sem dependências, pois nenhuma variável externa está mudando

  const loadUserData = useCallback(async () => {
    const resultGetMe = await getMe();
    setUserInfo(resultGetMe.data);
    setAuth(true);
  }, []); // Sem dependências, pois nenhuma variável externa está mudando

  const value = useMemo(() => {
    return {
      logout,
      loadUserData,
      setLayerVisible,
      setLayerOpenId,
      setEnvEntityStatus,
      reloadFetch,

      authenticated,
      agencyInformations,
      layerVisible,
      layerOpenedId,
      envEntityStatus,
      forceReloadFetch,
    };
  }, [
    logout,
    loadUserData,
    setLayerVisible,
    setLayerOpenId,
    setEnvEntityStatus,
    reloadFetch,
    authenticated,
    agencyInformations,
    layerVisible,
    layerOpenedId,
    envEntityStatus,
    forceReloadFetch,
  ]);

  useEffect(() => {
    if (getItemStorage(process.env.REACT_APP_TOKEN_AUTH_NAME)) {
      loadUserData();
    }
  }, [loadUserData]); // Inclua `loadUserData` nas dependências

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;

import axios from 'axios'
import getAuthorization from './get-authorization';
import addInterceptorResponse from './interceptor-response';

let axiosInstance;

const axiosGlobalInstance = () => {
  if (!axiosInstance) {
    const token = getAuthorization();

    axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_HOST_API,
      timeout: 30000,
      headers: {
        Authorization: token,
      },
    });

    addInterceptorResponse(axiosInstance);
  }

  return axiosInstance;
};

export default axiosGlobalInstance;

import { Component } from "react";

const canUseDOM = !!(
  (typeof window !== 'undefined' &&
  window.document && window.document.createElement)
);

class Intercom extends Component {
  constructor(props) {
    super(props);

    const {
      appID,
      appSource,
      ...otherProps
    } = props;

    if (!appID || !canUseDOM || !appSource) {
      return;
    }

    if (!window.Intercom) {
      (function(w, d, id, s) {
        function i() {
            i.c(arguments);
        }
        i.q = [];
        i.c = function(args) {
            i.q.push(args);
        };
        w.Intercom = i;
        s = d.createElement('script');
        s.async = 1;
        s.src = 'https://widget.intercom.io/widget/' + id;
        d.head.appendChild(s);
      })(window, document, appID, appSource);
    }

    window.intercomSettings = { ...otherProps, app_id: appID, app_source: appSource };

    if (window.Intercom) {
      window.Intercom('boot', otherProps);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      appID,
      appSource,
      ...otherProps
    } = nextProps;

    if (!canUseDOM) return;

    window.intercomSettings = { ...otherProps, app_id: appID, app_source: appSource };

    if (window.Intercom) {
      window.Intercom('update', otherProps);
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    if (!canUseDOM || !window.Intercom) return false;

    window.Intercom('shutdown');

    delete window.Intercom;
  }

  render() {
    return false;
  }
}

export default Intercom;

import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockCustomerDetail from './fakes/customer-detail';

const customerDetail = (id) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockCustomerDetail), 500);
    });
  }

  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'GET',
    url: `/customers/${id}`,
  });
}

export default customerDetail;


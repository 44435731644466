import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Image, Text, Anchor } from 'grommet';

import urlRoutes from '../../config/url-routes';
import logoKenlo from '../../imgs/logo-kenlo.png';

const Header = ({ title }) => {
  const history = useHistory();

  return (
    <Box
      pad='xsmall'
      direction='row'
      justify='center'
      background='#F2F2F2'
      elevation='xsmall'
      width='100vw'
      height='50px'
    >
      <Box
        direction='row'
        width='95vw'
        justify='between'
        align='center'
      >
        <Box
          gap='medium'
          direction='row'
          align='center'
        >
          <Anchor
            title='Página inicial'
            label={<Image src={logoKenlo} width='40px' />}
            onClick={() => history.push(urlRoutes.migrationsUrl)}
          />

          <Text>{title}</Text>
        </Box>

        <Box>
          <Anchor
            label='Sair'
            color='gray'
            onClick={() => history.push(urlRoutes.logout)}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Header;

import { useRef, useState, useCallback, useEffect } from 'react';

const useForm = (initialValues) => {
  const initial = useRef(initialValues);

  const [values, setValues] = useState(initialValues);
  const [pristine, setPristine] = useState(true);

  const [errors, setErrors] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const changeValues = useCallback((key, value) =>
    setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    })), []);

  const addOnListValues = useCallback((key, props) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: [...prevValues[key], props],
    }));
  }, []);

  const removeOnListValues = useCallback((key, props) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: prevValues[key].filter(item => item !== props),
    }));
  }, []);

  const initialize = useCallback((initialValues) => {
    if (!initialValues) {
      setValues(initial.current);
    } else {
      initial.current = initialValues;
      setValues(initialValues);
    }
  }, []);

  const handleSubmit = useCallback(({ resource, callbackSuccess }) => {
    setSubmitting(true);
    setErrors(false);
    setSuccessful(false);

    resource
      .then(() => {
        setErrors(false);
        setPristine(true);
        setSuccessful(true);

        if (callbackSuccess) callbackSuccess();
      })
      .catch(() => setErrors(true))
      .finally(() => setSubmitting(false));
  }, []);

  const resetEvents = useCallback(() => {
    setErrors(false);
    setSuccessful(false);
    setSubmitting(false);
  }, []);

  useEffect(() => {
    if (!initial?.current) return;

    const dirty = !!Object.keys(initial.current).find((key) =>
      initial.current[key] !== values[key]
    );

    setPristine(!dirty);
  }, [values]);

  return {
    values,
    errors,
    successful,
    submitting,
    pristine,

    changeValues,
    addOnListValues,
    removeOnListValues,
    initialize,
    handleSubmit,
    resetEvents,
  };
}

export default useForm;

import { useEffect, useContext, useState, useCallback } from 'react';

import { AppContext } from '../context';

const useFetchDetail = ({ get, layerType }) => {
  const { layerOpenedId, layerVisible } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [detailData, setData] = useState({});
  const [requestError, setRequestError] = useState(false);
  const [retry, setRetry] = useState(0);

  const retryRequest = useCallback(() => setRetry(retry + 1));

  useEffect(() => {
    if(!layerVisible || layerVisible !== layerType)
      return;

    setLoading(true);
    setRequestError(false);

    get(layerOpenedId)
      .then(({ data }) => setData(data))
      .catch(() => setRequestError(true))
      .finally(() => setLoading(false))

  }, [retry, layerVisible]);

  return {
    loading,
    detailData,
    requestError,

    retryRequest,
  }
}

export default useFetchDetail;

import React from 'react';
import { ResponsiveContext, Text, Box } from 'grommet';

const TableMessages = ({
  message = 'Não existem registros para serem migrados'
}) => (
  <ResponsiveContext.Consumer>
    {size => (
      <Box
        align='center'
        justify='center'
        pad={{
          vertical: size === 'small' ? 'medium' : 'small',
          horizontal: size === 'small' ? 'large' : 'medium',
        }}
      >
        <Text size='medium' color='dark-5' textAlign='center'>
          {message}
        </Text>
      </Box>
    )}
  </ResponsiveContext.Consumer>
)

export default TableMessages;

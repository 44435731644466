import React, { useRef } from 'react';
import { MaskedInput } from 'grommet';

import { isValidDate } from '../../commons';

const CalendarInput = props => {
  const { date, ...rest } = props;

  const untouched = useRef(date);
  const maskedInputDate = untouched.current === date && isValidDate(new Date(date)) ? new Date(date).toLocaleDateString() : date;

  return (
    <MaskedInput
      value={maskedInputDate}
      mask={[
        { length: 2,
          regexp: /^[1-2][0-9]$|^0?[1-9]$|^3[0-1]$|^0$/,
        },
        { fixed: '/' },
        {
          length: 2,
          regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
        },
        { fixed: '/' },
        {
          length: 4,
          regexp: /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
        }
      ]}
      {...rest}
    />
  )
};

export default CalendarInput;

import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockCustomerList from './fakes/customer-listing';

const getCustomerList = (params) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockCustomerList), 1000);
    });
  }

  const { migrationId, ...rest } = params;
  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'GET',
    url: `/migrations/${migrationId}/customers`,
    params: rest
  });
}

export default getCustomerList;


import React from 'react';
import { Box, Button, Text, ResponsiveContext } from 'grommet';
import { FormPrevious, FormNext } from 'grommet-icons';

import { formatNumber } from '../../commons';

const getPageIndices = (begin, end) => {
  const indices = [];

  for (let i = begin; i <= end; i++) {
    indices.push(i);
  }

  return indices;
}

const Pagination = ({
  totalOfRows,
  page,
  perPage,
  onNavigate,
}) => {

  if (totalOfRows <= perPage) {
    return null;
  }

  const numberEdgePages = 1;
  const numberMiddlePages = 4;

  const disableNextPageButton = !(
    page * perPage < totalOfRows
  );

  const disablePrevPageButton = page === 1;
  const totalPages = Math.ceil(totalOfRows / perPage);

  const beginPages = [1];
  const endPages = [totalPages];

  const startingMiddlePages = Math.min(
    page - Math.floor(numberMiddlePages / 2) + 1,
    totalPages - numberEdgePages - numberMiddlePages,
  );

  const middlePagesBegin = Math.max(startingMiddlePages, numberEdgePages + 2);
  const middlePagesEnd = Math.min(
    Math.max(
      page + Math.floor(numberMiddlePages / 2),
      numberEdgePages + numberMiddlePages + 1,
    ),
    endPages.length > 0 ? endPages[0] - 2 : totalPages - 1,
  );

  const middlePages = getPageIndices(middlePagesBegin, middlePagesEnd);

  let beginFlex = [];

  if(middlePagesBegin > numberEdgePages + 2) beginFlex= ['separator'];
  else if(numberEdgePages + 1 < totalPages - numberEdgePages)
    beginFlex = [numberEdgePages + 1];

  let endFlex = [];

  if (middlePagesEnd < totalPages - numberEdgePages - 1)
    endFlex = ['separator'];
  else if (totalPages - numberEdgePages > numberEdgePages)
    endFlex = [totalPages - numberEdgePages];

  const controls = [
    ...beginPages,
    ...beginFlex,
    ...middlePages,
    ...endFlex,
    ...endPages,
  ];

  return (
    <ResponsiveContext.Consumer>
      {size => {
        return (
          <Box
            direction='row'
            align='center'
            justify='center'
            pad={{ top: size === 'small' ? 'large' : 'medium' }}
            gap='medium'
          >
            <Button
              primary
              size='small'
              color='dark-3'
              icon={<FormPrevious size='small' />}
              onClick={() => onNavigate(page - 1)}
              disabled={disablePrevPageButton}
            />

            {controls.map((control, index) => {
              return control === 'separator' ? (<Text key={index} size='small' color='dark-1'>...</Text>) : (
                <Button
                  key={index}
                  plain
                  size='small'
                  color='dark-1'
                  label={formatNumber(control)}
                  onClick={() => onNavigate(control)}
                  disabled={page === control}
                />)
            })}

            <Button
              primary
              size='small'
              color='dark-3'
              icon={<FormNext size='small' />}
              onClick={() => onNavigate(page + 1)}
              disabled={disableNextPageButton}
            />
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

export default Pagination;

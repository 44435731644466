const enterpriseDetail = {
  data: {
    _id: 2,
    name: 'Edf. Torre de Pedra',
    website: null,
    administration: 'Adm Contruções e Soluções',
    floors: 12,
    units_floors: 2,
    construction_year: 1976,
    location_street_address: 'Rua Pedro Gomes',
    location_street_number: 229,
    location_neighborhood: 'Remanso Campineiro',
    location_city: 'Hortolândia',
    location_state_abbreviation: 'SP',
    location_zipcode: 13345098,
    status: 'Pronto para morar',
    status_id: 1,
    description: 'Descrição para apresentar no site',
    general_description: 'Comentários internos - apenas para a imobiliária',
    type: 'Casas residenciais',

    details: [
      { label: 'Piscina', value: true },
      { label: 'Quadra de Tenis', value: true },
      { label: 'Água', value: true },
      { label: 'Área de serviço', value: 2 },
      { label: 'Armário área de Serviço', value: true },
      { label: 'Armário banheiro', value: 1 },
      { label: 'Taco de madeira', value: true },
      { label: 'Varanda', value: false }
    ],

    photos: [
      {
        full_path: 'https://artifacts-bucket.s3.region.amazonaws.com/photos/1/property/file-name.jpg',
        original_photo_path: 'photos/1/property/file-name.jpg',
        in_order: 1,
        highlight: true,
        website: null
      },
      {
        full_path: 'https://artifacts-bucket.s3.region.amazonaws.com/photos/1/property/file-name.jpg',
        original_photo_path: 'photos/1/property/file-name.jpg',
        in_order: 1,
        highlight: true,
        website: null
      }
    ],

    archives: [
      {
        full_path: 'https://artifacts-bucket.s3.region.amazonaws.com/files/1/property/file-name.pdf',
        archive_name: 'nome do arquivo em forma de sentença beeeem grandenome do arquivo em forma de sentença beeeem grandenome do arquivo em forma de sentença beeeem grande.pdf',
        original_archive_path: 'files/1/property/file-name.pdf',
      },
      {
        full_path: 'https://artifacts-bucket.s3.region.amazonaws.com/files/1/property/file-name.pdf',
        archive_name: 'file-name.pdf',
        original_archive_path: 'files/1/property/file-name.pdf',
      },
    ],
  }
}

export default enterpriseDetail;

import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockMigrationList from './fakes/migration-listing';

const getMigrationList = (params) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockMigrationList), 500);
    });
  }

  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'GET',
    url: '/migrations',
    params
  });
}

export default getMigrationList;


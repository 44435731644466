import { useCallback, useContext } from 'react';
import { AppContext } from '../context';

const useCloseLayer = () => {
  const { setLayerOpenId, setLayerVisible } = useContext(AppContext);

  const close = useCallback(() => {
    setLayerVisible(null);
    setLayerOpenId(null);
  }, []);

  return {
    close,
  }
}

export default useCloseLayer;

export default {
  global: {
    colors: {
      brand: {
        dark: '#116f84',
        light: '#116f84'
      },
      border: {
        light: 'rgba(0, 0, 0, 0.15)',
      }
    },

    drop: {
      border: { radius: '.3rem' },
      shadowSize: 'medium',
    },


    input: {
      weight: 400
    },

    focus: {
      border: { color: 'none' }
    }
  },

  carousel: {
    animation: {
      duration: 600,
    },
    icons: {
      color: 'black',
    },
    disabled: {
      icons: {
        color: 'grey',
      },
    },
  },

  radioButton: {
    size: '18px',
  },

  anchor: {
    fontWeight: 400
  },
}

import React, { useContext, useEffect} from 'react';
import { Box, Layer, TextInput, Anchor, Button } from 'grommet';

import { userDetail, edit } from '../../../../api';

import { Placeholder, Loading, Container, Title, Alert, SubTitle } from '../../../../components';
import { TitleField, CalendarInput, TelephoneInput } from '../../../../components/form';

import { AppContext } from '../../../../context';
import { useForm, useFetchDetail, useCloseLayer } from '../../../../hooks';

import { preprocessorSend, formatEditObject } from '../../utils';

import { entities, layers, messageDictionary } from '../../../../constants';

const UserEdit = () => {
  const { close } = useCloseLayer();
  const { layerVisible, reloadFetch } = useContext(AppContext);

  const { loading, detailData, requestError, retryRequest } = useFetchDetail({
    get: userDetail,
    layerType: layers.edit
  });

  const { values, errors, pristine, submitting, handleSubmit, changeValues, initialize, resetEvents } = useForm();

  useEffect(() => {
    if (!layerVisible || !detailData) return;
  
    initialize(formatEditObject(detailData));
  }, [layerVisible, detailData, initialize]); 

  if(!layerVisible || layerVisible !== layers.edit)
    return null;

  return (
    <Layer
      responsive={false}
      full='vertical'
      position='left'
      onClickOutside={() => {
        close();
        resetEvents();
      }}
      onEsc={() => {
        close();
        resetEvents();
      }}
    >
      <Box
        fill='vertical'
        width='60vw'
        overflow='auto'
        flex
      >
        {requestError ? (
          <Placeholder
            title='Ops.'
            message={messageDictionary.requestFail}
            button={{
              label: 'Tentar novamente',
              action: retryRequest,
            }}
          />
        ) : null}

        {loading && !requestError ? (<Loading />) : null}

        {!loading && !requestError ? (
          <Container>
            <Title value='Editar usuário' />


            {/* apelido e nome */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box width='medium'>
                <TitleField text='Apelido' required />

                <TextInput
                  maxLength={64}
                  autoComplete='disabled'
                  value={values.nickname ?? ''}
                  name='nickname'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>

              <Box width='large'>
                <TitleField text='Nome completo' required />

                <TextInput
                  maxLength={70}
                  autoComplete='disabled'
                  value={values.full_name ?? ''}
                  name='full_name'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            {/* e-mail e nascimento */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box width='large'>
                <TitleField text='E-mail' required />

                <TextInput
                  maxLength={64}
                  autoComplete='disabled'
                  value={values.email ?? ''}
                  name='email'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>

              <Box width='medium'>
                <TitleField text='Data de nascimento' required />

                <CalendarInput
                  placeholder='00/00/0000'
                  date={values.born_date ?? ''}
                  name='born_date'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            <SubTitle text='Contatos' />

            {/* telefone */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box fill='horizontal'>
                <TitleField text='Telefone principal' />

                <TelephoneInput
                  maxLength={25}
                  autoComplete='disabled'
                  value={values.phone ?? ''}
                  name='phone'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            {/* celular */}
            <Box
              direction='row'
              gap='small'
              margin={{ bottom: 'small' }}
            >
              <Box fill='horizontal'>
                <TitleField text='Celular' />

                <TelephoneInput
                  maxLength={25}
                  autoComplete='disabled'
                  value={values.cellphone ?? ''}
                  name='cellphone'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            {/* Rádio */}
            <Box
              direction='row'
              gap='small'
              margin={{ bottom: 'small' }}
            >
              <Box fill='horizontal'>
                <TitleField text='Rádio' />

                <TextInput
                  maxLength={25}
                  autoComplete='disabled'
                  value={values.radio ?? ''}
                  name='radio'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>

            {errors && <Alert alertType='error' message={messageDictionary.requestFail} />}

            {/* botões */}
            <Box
              direction='row'
              pad={{ vertical: 'medium' }}
              justify='center'
              align='center'
              gap='small'
            >
              <Box width='xsmall'>
                <Anchor
                  color='dark-3'
                  label='Cancelar'
                  onClick={() => {
                    close();
                    resetEvents();
                  }}
                />
              </Box>

              <Box width='small'>
                <Button
                  color='brand'
                  primary
                  label={!submitting ? 'Atualizar' : 'Atualizando...'}
                  disabled={pristine || submitting}
                  onClick={() =>
                    handleSubmit({
                      resource: edit({ entity: entities.user, payload: preprocessorSend(values) }),
                      callbackSuccess: () =>
                        new Promise(
                          () => setTimeout(() => {
                            close();
                            reloadFetch();
                          }, 100)
                        )
                    })
                  }
                />
              </Box>
            </Box>

          </Container>
        ) : null}
      </Box>
    </Layer>
  );
}

export default UserEdit;

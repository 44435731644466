const formatEditObject = (values) => ({
  _id: values._id,
  name: values.name,
  customer_media_id: values.customer_media_id,
  gender_id: values.gender_id,
  person_type_id: values.person_type_id,
  emails: values.emails,
  phones: values.phones,
});

export default formatEditObject;

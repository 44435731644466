import React, { useContext, Fragment } from 'react';
import { Box, Layer } from 'grommet';

import { formatDatetime, isInteger, mask, fillZipcode, formatNumber } from '../../../../commons';
import { propertyDetail } from '../../../../api';
import {
  Alert,
  Placeholder,
  Loading,
  Container,
  Title,
  SubTitle,
  Description,
  Word,
  Wrapper,
  BadgeList,
  Separator,
  FileList,
  ImageList,
} from '../../../../components';

import { AppContext } from '../../../../context';
import { useFetchDetail, useCloseLayer } from '../../../../hooks';

import { layers, messageDictionary } from '../../../../constants';

const PropertyDetail = () => {
  const { close } = useCloseLayer();
  const { layerVisible } = useContext(AppContext);

  const { loading, detailData, requestError, retryRequest } = useFetchDetail({
    get: propertyDetail,
    layerType: layers.detail
  });

  if(!layerVisible || layerVisible !== layers.detail)
    return null;

  return (
    <Layer
      responsive={false}
      full='vertical'
      position='left'
      onClickOutside={close}
      onEsc={close}
    >
      <Box
        fill='vertical'
        width='60vw'
        overflow='auto'
        flex
      >
        {requestError ? (
          <Placeholder
            title='Ops.'
            message={messageDictionary.requestFail}
            button={{
              label: 'Tentar novamente',
              action: retryRequest,
            }}
          />
        ) : null}

        {loading && !requestError ? (<Loading />) : null}

        {!loading && !requestError ? (
          <Container>
            <Title value='Detalhes do imóvel' />

            <Wrapper>
              <Word title='Referência alternativa' text={detailData.property_secondary_reference} />
              <Word title='Tipo' text={detailData.usage_type} />
              <Word title='Finalidade' text={detailData.usage} />
              <Word title='Proprietário' text={detailData.customer_migration_name} />
              <Word title='Condomínio' text={detailData.enterprise_migration_name} />
            </Wrapper>

            <Separator />

            <SubTitle text='Endereço' />

            <Alert
              alertType='warning'
              message={messageDictionary.warningZipcode}
            />

            <Wrapper>
              <Word title='Logradouro' text={detailData.location_street_address} />
              <Word title='Número' text={detailData.location_street_number} />
              <Word title='Complemento' text={detailData.location_add_on_address} />
              <Word title='Bairro' text={detailData.location_neighborhood} />
              <Word title='Cidade' text={detailData.location_city} />
              <Word title='Estado' text={detailData.location_state} />
              <Word title='CEP' text={mask(fillZipcode(detailData.location_zipcode), '##.###-###')} />
              <Word title='Ponto de referência' text={detailData.location_nearby} />
            </Wrapper>

            <Separator />

            <SubTitle text='Valores' />

            <Wrapper>
              <Word title='Venda' text={formatNumber(detailData.sale_price, 'currency', 2)} />
              <Word title='Locação' text={formatNumber(detailData.rent_price, 'currency', 2)} />
              <Word title='Condomínio' text={formatNumber(detailData.condo_price, 'currency', 2)} />
              <Word title='IPTU/ITR' text={formatNumber(detailData.municipal_property_tax, 'currency', 2)} />
            </Wrapper>

            <Separator />

            <SubTitle text='Áreas' />

            <Wrapper>
              <Word title='Total' text={formatNumber(detailData.area_built)} />
              <Word title='Útil/Construída' text={formatNumber(detailData.area_useful)} />
              <Word title='Privativa' text={formatNumber(detailData.area_private)} />
              <Word title='Comum' text={formatNumber(detailData.area_common)} />
            </Wrapper>

            <Separator />

            <SubTitle text='Características' />

            <BadgeList
              list={
                detailData.details
                  .filter(d => d.value)
                  .map(d =>
                    isInteger(d.value) ? `${d.value} ${d.label}` : d.label
                  )
              }
            />

            <Separator />

            <SubTitle text='Informações de exclusividade' />

            <Wrapper>
              <Word title='Exclusividade' text={detailData.exclusivity ? 'Sim' : 'Não'} />
              <Word title='Data início exclusividade' text={formatDatetime(detailData.exclusivity_begin_date)} />
              <Word title='Data fim exclusividade' text={formatDatetime(detailData.exclusivity_date)} />
            </Wrapper>

            <Separator />

            <SubTitle text='Informações de placa' />

            <Wrapper>
              <Word title='Com placa?' text={detailData.advertising ? 'Sim' : 'Não'} />
              <Word title='Tipo' text={detailData.advertising_type} />
              <Word title='Classificação' text={detailData.advertising_classification} />

              {detailData.advertising_solicitation_date && (
                <Word title='Data solicitação colocação' text={formatDatetime(detailData.advertising_solicitation_date)} />
              )}

              {detailData.advertising_date && (
                <Word title='Data de colocação' text={formatDatetime(detailData.advertising_date)} />
              )}

              {detailData.advertising_solicitation_date_end && (
                <Word title='Data solicitação retirada' text={formatDatetime(detailData.advertising_solicitation_date_end)} />
              )}

              {detailData.advertissing_date_end && (
                <Word title='Data de retirada' text={formatDatetime(detailData.advertissing_date_end)} />
              )}
            </Wrapper>

            <Separator />

            <SubTitle text='Dados primários' />

            <Wrapper>
              <Word title='Status' text={detailData.status} />
              <Word title='Aceita permuta?' text={detailData.property_exchange ? 'Sim' : 'Não'} />
              <Word title='Aceita financiamento' text={detailData.mortgage ? 'Sim' : 'Não'} />
              <Word title='Padrão' text={detailData.standard} />
              <Word title='Padrão da localização' text={detailData.localization} />
              <Word title='Posição solar/face' text={detailData.face} />
              <Word title='Posição' text={detailData.position} />
              <Word title='Topografia' text={detailData.topography} />
              <Word title='Ano de construção' text={detailData.construction_year} />
              <Word title='Ano de reforma' text={detailData.construction_reform_year} />
              <Word title='Máximo de ocupantes' text={detailData.max_occupants} />
              <Word title='Dimensão do terreno' text={detailData.property_dimension} />
              <Word title='Local das chaves' text={detailData.property_keys_place} />
              <Word title='Condição de pagamento IPTU/ITR' text={detailData.municipal_property_tax_condition} />
              <Word title='Data de cadastro' text={formatDatetime(detailData.register_date)} />
              <Word title='Última atualização' text={formatDatetime(detailData.updated_date)} />
            </Wrapper>

            {detailData.property_general_description && (
              <Description
                title='Comentários internos'
                text={detailData.property_general_description}
              />
            )}

            <Separator />

            <SubTitle text='Publicação na web' />

            <Wrapper>
              <Word title='Anunciar' text={detailData.published_on_site ? 'Sim' : 'Não'} />
              <Word title='Destaque' text={detailData.highlight ? 'Sim' : 'Não'} />
              <Word title='Link vídeo no Youtube' text={detailData.youtube_media} />
            </Wrapper>

            <Description
              title='Descrição do site'
              text={detailData.property_description}
            />

            <Description
              title='Descrição em mídias impressas'
              text={detailData.property_description_midia}
            />

            <Separator />

            <SubTitle text='Comissões e condições' />

            <Wrapper>
              {detailData.brokers && detailData.brokers.length > 0 &&
                detailData.brokers.map((user, index) => (
                  <Word
                    key={index}
                    title={user.comission_type}
                    text={user.name}
                  />
                ))
              }
            </Wrapper>

            {detailData.property_commercial_condition && (
              <Description
                title='Condição comercial'
                text={detailData.property_commercial_condition}
              />
            )}

            <Separator />

            <SubTitle text='Confidencial' />

            <Wrapper>
              <Word title='Código IPTU/ITR' text={detailData.municipal_property_code} />
              <Word title='Código rede de eletricidade' text={detailData.municipal_property_electricity_code} />
              <Word title='Código rede de água' text={detailData.municipal_property_sanitation_code} />
              <Word title='Número da matrícula' text={detailData.municipal_property_number} />

              {detailData.property_documentation && (
                <Description
                  title='Observações da documentação'
                  text={detailData.property_documentation}
                />
              )}
            </Wrapper>

            <Separator />

            {detailData.photos && detailData.photos.length > 0 && (
              <Fragment>
                <SubTitle text='Fotos' />

                <ImageList
                  list={
                    detailData.photos.map(a => ({ order: a.in_order, url: a.full_path, highlight: a.highlight, website: a.website }))
                  }
                />
              </Fragment>
            )}

            {detailData.archives && detailData.archives.length > 0 && (
              <Fragment>
                <SubTitle text='Arquivos' />

                <FileList propName='archive_name' propUrl='full_path' items={detailData.archives} />
              </Fragment>
            )}
          </Container>
        ) : null}
      </Box>
    </Layer>
  );
}

export default PropertyDetail;

import { useCallback, useEffect, useState } from 'react';
import { migrationSummary } from '../api';
import { getItemStorage, setItemStorage } from '../commons';

const useMigrationSummary = (migrationId, forceGet) => {
  const [loading, setLoading] = useState(true);
  const [requestError, setRequestError] = useState(false);
  const [data, setData] = useState({
    brokers: {},
    customers: {},
    enterprises: {},
    properties: {},
  });

  const setLocalStorage = useCallback((key, value) => setItemStorage(key, JSON.stringify(value)), [setItemStorage]);
  const checkLocalStorage = useCallback((migrationId) => getItemStorage(migrationId), [getItemStorage]);

  useEffect(() => {
    if (!migrationId) return;

    setLoading(true);
    setRequestError(false);

    const resultLocalStorage = checkLocalStorage(migrationId);

    if (resultLocalStorage && !forceGet) {
      setData(JSON.parse(resultLocalStorage));
      setLoading(false);
      return;
    }

    migrationSummary(migrationId)
      .then(({ data }) => {
        setData(data);
        setLocalStorage(migrationId, data);
      })
      .catch(() => setRequestError(true))
      .finally(() => setLoading(false));
  }, [migrationId, forceGet, checkLocalStorage, setLocalStorage]);

  return {
    loading,
    data,
    requestError,
    setLoading,
  };
};

export default useMigrationSummary;

import React from 'react';
import { Box, Text } from 'grommet';

const componentStyle = (type) => {
  const types = {
    success: {
      background: '#75d6a1',
      textColor: '#00873d'
    },
    warning: {
      background: '#ffe5c0',
      textColor: '#b16d0b'
    },
    error: {
      background: '#f2d8db',
      textColor: '#632026'
    },
    default: {
      background: '#d3d5da',
      textColor: '#6f747c'
    }
  };

  return types[type];
}

const Alert = ({
  message = '',
  alertType = 'default',
}) => {
  const { background, textColor } = componentStyle(alertType);

  return (
    <Box
      round='xxsmall'
      background={background}
      margin={{
        vertical: 'xsmall'
      }}
      pad='small'
      fill='horizontal'
      align='center'
      flex={{ shrink: 0 }}
    >
      <Text color={textColor} size='small' weight='bold'>{message}</Text>
    </Box>
  )
}

export default Alert;

import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Anchor, Button, Text } from 'grommet';
import { StatusGood } from 'grommet-icons';

import { postEntityChangeStatus } from '../../api';
import { formatNumber } from '../../commons';

import { useMigrationEntityStatus, useMigrationSummary } from '../../hooks';

import urlRoutes from '../../config/url-routes';

const Breadcrumbs = () => {
  const { push } = useHistory();
  const { migrationId } = useParams();

  const { loading, data, requestError } = useMigrationSummary(migrationId);
  const { envEntityStatus, setStateChangeEntityStatus } = useMigrationEntityStatus(migrationId);

  const handlerPost = ({ migrationId, entity, status }) => {
    setStateChangeEntityStatus({ entity, status });

    postEntityChangeStatus({
      entity,
      status,
      migrationId,
    });
  }

  const redirect = (url) => push(url.replace(/:migrationId/gi, migrationId));

  const listOptions = () => {
    return [
      {
        entity: 'brokers',
        label: 'Usuários',
        url: urlRoutes.usersUrl,
        total: data.brokers?.total,
        isApproved: envEntityStatus.brokers,
      },
      {
        entity: 'customers',
        label: 'Clientes',
        url: urlRoutes.customersUrl,
        total: data.customers?.total,
        isApproved: envEntityStatus.customers,
      },
      {
        entity: 'properties',
        label: 'Imóveis',
        url: urlRoutes.propertiesUrl,
        total: data.properties?.total,
        isApproved: envEntityStatus.properties,
      },
      {
        entity: 'enterprises',
        label: 'Empreend.',
        url: urlRoutes.enterprisesUrl,
        total: data.enterprises?.total,
        isApproved: envEntityStatus.enterprises,
      },
    ]
  }

  if(requestError) return null;

  return (
    <Box
      direction='row'
      margin={{ bottom: 'medium' }}
      gap='medium'
    >
      {listOptions().map((item, index) => (
        <Box
          key={index}
          direction='row'
          width='xsmall'
          height='xxsmall'
          gap='xsmall'
        >
          <Box
            align='end'
          >
            {loading ?
              (<Box background='light-3' width='4vw' height='8vh' />) :
              (<Text weight='bold' color='dark-2' size='medium'>{formatNumber(item.total)}</Text>)
            }

            <Anchor
              label={<Text color='brand' size='small'>{item.label}</Text>}
              onClick={() => redirect(item.url)}
            />
          </Box>

          <Box>
            <Button
              plain
              title={ item.isApproved ? 'Desmarcar como validado' : 'Marcar como validado' }
              label={<StatusGood color={ item.isApproved ? 'status-ok' : 'light-4' } />}
              onClick={() =>
                handlerPost({
                  migrationId: migrationId,
                  entity: item.entity,
                  status: !item.isApproved,
                })
              }
            />
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default Breadcrumbs;

import { useCallback, useContext } from 'react';
import { AppContext } from '../context';

const useOpenLayer = () => {
  const { setLayerOpenId, setLayerVisible } = useContext(AppContext);

  const open = useCallback(({ entityIdentifier, layer }) => {
    setLayerVisible(layer);
    setLayerOpenId(entityIdentifier);
  }, [setLayerVisible, setLayerOpenId]); // Inclua as dependências aqui

  return {
    open
  };
};

export default useOpenLayer;

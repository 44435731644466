const list = [
  { id: '1', name: 'Padrão' },
  { id: '2', name: 'Lançamento' },
  { id: '3', name: 'Pré-lançamento' },
  { id: '4', name: 'Futuro lançamento' },
  { id: '5', name: 'Últimas unidades' },
  { id: '6', name: 'Revenda' },
  { id: '7', name: 'Pronto para Morar' },
];

export default list;

import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockDetail from './fakes/migration-summary';

const migrationSummary = (migrationId) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockDetail), 500);
    });
  }

  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'GET',
    url: `/migrations/${migrationId}/summaries`,
  });
}

export default migrationSummary;

import { useCallback, useContext, useEffect } from 'react';
import { getEntityStatus } from '../api';

import { AppContext } from '../context';

const useMigrationEntityStatus = (migrationId) => {
  const { envEntityStatus, setEnvEntityStatus } = useContext(AppContext);

  const setStateChangeEntityStatus = useCallback(
    ({ entity, status }) => {
      setEnvEntityStatus((prevState) => ({
        ...prevState,
        [entity]: status,
      }));
    },
    [setEnvEntityStatus] // Adiciona `setEnvEntityStatus` como dependência
  );

  useEffect(() => {
    if (envEntityStatus.migrationId && envEntityStatus.migrationId === migrationId) {
      return;
    }

    getEntityStatus(migrationId)
      .then(({ data }) => {
        setEnvEntityStatus({
          ...data,
          migrationId,
        });
      });
  }, [envEntityStatus.migrationId, migrationId, setEnvEntityStatus]); // Adiciona todas as dependências

  return { envEntityStatus, setStateChangeEntityStatus };
};

export default useMigrationEntityStatus;

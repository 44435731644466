import React from 'react';
import { Box, Text } from 'grommet';

const PlainCard = ({ title, listKeyValue }) => {
  return (
    <Box
      direction='column'
      width='18vw'
      pad={{ vertical: 'small', horizontal: 'small' }}
      border={{
        side: 'all',
        color: 'light-4'
      }}
      round='xsmall'
      margin={{ bottom: 'small' }}
      justify='center'
    >
      <Text
        size='medium'
        color='dark-2'
        truncate={true}
      >
        {title}
      </Text>

      <Box>
        {listKeyValue.map((item, index) => (
          <Box
            key={index}
            direction='row'
            align='baseline'
            gap='xsmall'
          >
            <Text
              size='medium'
              textAlign='start'
              weight='bold'
            >
              {item.key}
            </Text>

            <Text
              size='small'
              color='dark-2'
              textAlign='start'
              title={item.value}
              truncate={true}
            >
              {item.value}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default PlainCard;

import React, { useContext, Fragment } from 'react';
import { Box, Layer } from 'grommet';

import { isInteger, mask, fillZipcode } from '../../../../commons';
import { enterpriseDetail } from '../../../../api';
import {
  Alert,
  Placeholder,
  Loading,
  Container,
  Title,
  SubTitle,
  Description,
  Word,
  Wrapper,
  BadgeList,
  Separator,
  FileList,
  ImageList,
} from '../../../../components';

import { AppContext } from '../../../../context';
import { useFetchDetail, useCloseLayer } from '../../../../hooks';

import { layers, messageDictionary } from '../../../../constants';

const EnterpriseDetail = () => {
  const { close } = useCloseLayer();
  const { layerVisible } = useContext(AppContext);

  const { loading, detailData, requestError, retryRequest } = useFetchDetail({
    get: enterpriseDetail,
    layerType: layers.detail
  });

  if(!layerVisible || layerVisible !== layers.detail)
    return null;

  return (
    <Layer
      responsive={false}
      full='vertical'
      position='left'
      onClickOutside={close}
      onEsc={close}
    >
      <Box
        fill='vertical'
        width='60vw'
        overflow='auto'
        flex
      >
        {requestError ? (
          <Placeholder
            title='Ops.'
            message={messageDictionary.requestFail}
            button={{
              label: 'Tentar novamente',
              action: retryRequest,
            }}
          />
        ) : null}

        {loading && !requestError ? (<Loading />) : null}

        {!loading && !requestError ? (
          <Container>
            <Title value='Detalhes do empreendimento' />

            <Wrapper>
              <Word title='Nome' text={detailData.name} />
              <Word title='Hotsite' text={detailData.website} />
              <Word title='Administradora (empresa)' text={detailData.administration} />
              <Word title='Ano de construção' text={detailData.construction_year} />
              <Word title='Status' text={detailData.status} />
            </Wrapper>

            <Description title='Descrição do site' text={detailData.description} />
            <Description title='Comentários internos' text={detailData.general_description} />

            <Separator />

            <SubTitle text='Andares' />

            <Wrapper>
              <Word title='Total de andares' text={detailData.floors} />
              <Word title='Unidades por andar' text={detailData.units_floors} />
            </Wrapper>

            <Separator />

            <SubTitle text='Endereço' />

            <Alert
              alertType='warning'
              message={messageDictionary.warningZipcode}
            />

            <Wrapper>
              <Word title='Logradouro' text={detailData.location_street_address} />
              <Word title='Número' text={detailData.location_street_number} />
              <Word title='Bairro' text={detailData.location_neighborhood} />
              <Word title='Cidade' text={detailData.location_city} />
              <Word title='Estado' text={detailData.location_state_abbreviation} />
              <Word title='CEP' text={mask(fillZipcode(detailData.location_zipcode), '##.###-###')} />
            </Wrapper>

            <Separator />

            <SubTitle text='Características' />

            <BadgeList
              list={
                detailData.details
                  .filter(d => d.value)
                  .map(d =>
                    isInteger(d.value) ? `${d.value} ${d.label}` : d.label
                  )
              }
            />

            <Separator />

            {detailData.photos && detailData.photos.length > 0 && (
              <Fragment>
                <SubTitle text='Fotos' />

                <ImageList
                  list={
                    detailData.photos.map(a => ({ order: a.in_order, url: a.full_path, highlight: a.highlight, website: a.website }))
                  }
                />
              </Fragment>
            )}

            {detailData.archives && detailData.archives.length > 0 && (
              <Fragment>
                <SubTitle text='Arquivos' />

                <FileList propName='archive_name' propUrl='full_path' items={detailData.archives} />
              </Fragment>
            )}
          </Container>
        ) : null}
      </Box>
    </Layer>
  );
}

export default EnterpriseDetail;

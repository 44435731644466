import Chance from 'chance';

const detail = () => {
  const chance = new Chance();

  return {
    data: {
      brokers: {
        total: chance.integer({ min: 0, max: 20 }),
        no_email: chance.integer({ min: 1, max: 10 }),
        no_profile: chance.integer({ min: 1, max: 5 }),
      },

      customers: {
        total: chance.integer({ min: 0, max: 99999 }),
        no_emails: chance.integer({ min: 1, max: 99999 }),
        no_phones: chance.integer({ min: 1, max: 99999 }),
        no_broker: chance.integer({ min: 1, max: 99999 }),
      },

      enterprises: {
        total: chance.integer({ min: 0, max: 99999 }),
        accepted: chance.integer({ min: 0, max: 99999 }),
        no_zipcode: chance.integer({ min: 1, max: 5 }),
        no_photo: chance.integer({ min: 1, max: 99999 }),
        photos: chance.integer({ min: 0, max: 999999 }),
        archives: chance.integer({ min: 0, max: 99999 }),
      },

      properties: {
        total: chance.integer({ min: 0, max: 99999 }),
        accepted: chance.integer({ min: 0, max: 99999 }),
        no_broker: chance.integer({ min: 1, max: 99999 }),
        no_owners: chance.integer({ min: 1, max: 99999 }),
        no_zipcode: chance.integer({ min: 1, max: 99999 }),
        no_type: chance.integer({ min: 1, max: 99999 }),
        no_price: chance.integer({ min: 1, max: 99999 }),
        no_area: chance.integer({ min: 1, max: 99999 }),
        vertical_without_enterprise: chance.integer({ min: 1, max: 99999 }),
        vertical_without_add_on_address: chance.integer({ min: 1, max: 99999 }),
        photos: chance.integer({ min: 0, max: 999 }),
        archives: chance.integer({ min: 0, max: 999 }),
      },
    }
  }
}

export default detail();

import React, { Fragment, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, ResponsiveContext, Button, Text } from 'grommet';

import { postChangeStatus } from '../../../../api';
import { formatNumber } from '../../../../commons';
import { PageWrapper, Loading, ListLevel, Alert } from '../../../../components';

import { useForm, useMigrationSummary, useEnvironmentDetail } from '../../../../hooks';

import urlRoutes from '../../../../config/url-routes';

import { migrationStatus, messageDictionary } from '../../../../constants';

const FormRefuseMigration = () => {
  const { goBack, push } = useHistory();
  const { migrationId } = useParams();

  const { loading, data } = useMigrationSummary(migrationId);
  const { loading: loadingEnv, data: dataEnv } = useEnvironmentDetail(migrationId);
  const { values, errors, successful, submitting, handleSubmit, initialize } = useForm();

  useEffect(() => {
    initialize({
      migrationId,
      status: migrationStatus.analysisRefused,
    });
  }, [initialize, migrationId]); // Adiciona as dependências `initialize` e `migrationId`

  if(loading || loadingEnv) {
    return <Loading />;
  }

  if(successful || dataEnv.status !== migrationStatus.environmentReadyForAnalisys) {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <PageWrapper>
            <Box
              round='small'
              border={{ color: 'light-4' }}
              background='white'
              elevation='small'
              width={ size === 'small' ? '100vw' : '50vw' }
              pad={{
                horizontal: size === 'small' ? 'large' : 'medium',
                vertical: size === 'small' ? 'medium' : 'small'
              }}
              margin={{ bottom: 'medium' }}
            >
              <Text size='medium' textAlign='center' color='dark-3'>
                Verificamos que você não concordou com as informações, por favor, entre em contato com nossos atendentes pelo chat do intercom.
              </Text>
            </Box>

            <Box
              direction='row'
              gap='small'
            >
              <Button
                color='dark-3'
                label='Ok'
                onClick={() =>
                  push(urlRoutes.migrationsUrl)
                }
              />
            </Box>
          </PageWrapper>
        )}
      </ResponsiveContext.Consumer>
    )
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <PageWrapper>
          <Box
            margin={{ bottom: 'medium' }}
          >
            <Text
              size='large'
              color='dark-2'
              textAlign='center'
            >
              Deseja realmente RECUSAR essa migração?
            </Text>
          </Box>

          <Box
            round='small'
            border={{ color: 'light-4' }}
            background='white'
            elevation='small'
            width={ size === 'small' ? '100vw' : '50vw' }
            pad={{
              horizontal: size === 'small' ? 'large' : 'medium',
              vertical: size === 'small' ? 'medium' : 'small'
            }}
            margin={{ bottom: 'medium' }}
          >
            <Fragment>
              <ListLevel
                title = 'Total de imóveis'
                value = {formatNumber(data.properties?.total)}
              />

              <ListLevel
                title = 'Total de clientes'
                value = {formatNumber(data.customers?.total)}
              />

              <ListLevel
                title = 'Total de empreendimentos'
                value = {formatNumber(data.enterprises?.total)}
              />

              <ListLevel
                title = 'Total de usuários'
                value = {formatNumber(data.brokers?.total)}
              />
            </Fragment>
          </Box>

          {errors && (<Box width='50vw'>
            <Alert alertType='error' message={messageDictionary.requestFail} />
          </Box>)}

          <Box
            direction='row'
            gap='small'
          >
            <Button
              color='dark-3'
              label='Voltar'
              onClick={() =>
                goBack()
              }
            />

            <Button
              color='status-error'
              primary
              label={
                !submitting ? 'RECUSAR migração' : 'RECUSANDO migração...'
              }
              onClick={() =>
                handleSubmit({
                  resource: postChangeStatus(values),
                  callbackSuccess: null,
                })
              }
              disabled={submitting}
            />
          </Box>
        </PageWrapper>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default FormRefuseMigration;

import React from 'react';
import { MaskedInput } from 'grommet';

const TelephoneInput = props => (
  <MaskedInput
    placeholder='00 0000 0000'
    mask={[
      { fixed: '(' },
      { length: 2, regexp: /\d/ },
      { fixed: ')' },
      { fixed: ' ' },
      { length: 4, regexp: /\d/ },
      { fixed: '-' },
      { length: [4, 5], regexp: /\d/ }
    ]}
    {...props}
  />
)

export default TelephoneInput;

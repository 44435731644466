import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockUserDetail from './fakes/user-detail';

const userDetail = (id) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockUserDetail), 500);
    });
  }

  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'GET',
    url: `/users/${id}`,
  });
}

export default userDetail;


import Chance from 'chance';

const propertyList = () => {
  const chance = new Chance();

  const data = Array.from({ length: 40 }, () => ({
    _id: chance.guid(),
    property_secondary_reference: chance.string({ length: 5, casing: 'upper', symbols: false }),
    status: chance.pickone(['Ativo', 'Vendido', 'Cancelado', 'Locado']),
    usage_type: chance.pickone(['Casa', 'Apartamento', 'Apartamento Duplex', 'Galpão']),
    usage: chance.pickone(['Residencial', 'Comercial', 'Rural', 'Temporada']),
    location_street_address: chance.pickone(['Rua Pedro Gomes', 'Av. Carlos Filho', 'Via Rural', 'Rua Bento II']),
    location_neighborhood: chance.pickone(['Centro', 'Jardim Nova Campinas', 'Remanso das Águas', 'Amanda II']),
    location_city: chance.pickone(['Campinas', 'Sumaré', 'Hortolândia', 'Paulinia']),
    location_state: chance.pickone(['SP', 'MG', 'RJ', 'CE']),
    bedroom: chance.pickone([chance.integer({ min: 0, max: 5 }), null]),
    garages: chance.pickone([chance.integer({ min: 0, max: 3 }), null]),
  }));

  return {
    data: {
      data,
      total: data.length
    }
  }
}

export default propertyList();

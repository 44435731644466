import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from 'grommet';

import { getUserList, getUserFile } from '../../../../api';
import { PageWrapper, Breadcrumbs, MigrationActions, Pagination } from '../../../../components';
import { TableHeader, TableMessages, TableWrap, TableRow, TableCols, TableActionsCol } from '../../../../components/listing';

import { useOpenLayer, useFetchListing } from '../../../../hooks';

import { layers, pagination, messageDictionary } from '../../../../constants';

const SearchResult = () => {
  const { migrationId } = useParams();

  const { open } = useOpenLayer();

  const {
    loading,
    list,
    requestError,
    page,

    onNavigate
  } = useFetchListing({ migrationId, get: getUserList });

  return (
    <PageWrapper>
      <Box
        direction='row'
        justify='between'
        width='100vw'
        align='end'
      >
        <MigrationActions />
        <Breadcrumbs />
      </Box>

      <TableWrap>
        <TableHeader
          title='Usuários'
          totalItens={list.total}

          download={{
            fileName: 'usuarios.csv',
            resource: getUserFile,
          }}
        />

        {requestError && (
          <TableMessages message={messageDictionary.requestFail} />
        )}

        {loading && !requestError && (
          <TableMessages message={messageDictionary.waitingResponseRequest} />
        )}

        {!loading && !requestError && list.total <= 0 && (<TableMessages />)}

        {!loading && !requestError && list.data.map((row, index) => (
          <TableRow key={index}>
            <TableCols
              columns={[
                { width: 'small', value: row.full_name },
                { width: 'medium', value: row.email },
                { width: 'small', value: row.profile }
              ]}
            />

            <TableActionsCol
              actions={[
                {
                  label: 'editar',
                  action: () => open({ entityIdentifier: row._id, layer: layers.edit }),
                },
                {
                  label: 'detalhes',
                  action: () => open({ entityIdentifier: row._id, layer: layers.detail }),
                }
              ]}
            />
          </TableRow>
        ))}
      </TableWrap>

      <Pagination
        totalOfRows={list.total}
        page={page}
        perPage={pagination.limit}
        onNavigate={onNavigate}
      />
    </PageWrapper>
  )
}

export default SearchResult;

const formatEditObject = (values) => ({
  _id: values._id,
  nickname: values.nickname,
  full_name: values.full_name,
  email: values.email,
  born_date: values.born_date ? new Date(values.born_date).toLocaleDateString() : null,
  phone: values.phone,
  cellphone: values.cellphone,
  radio: values.radio,
});

export default formatEditObject;

import { mask } from '../../../commons';

const formatEditObject = (values) => ({
  _id: values._id,
  usage_id: values.usage_id,
  usage_type_id: values.usage_type_id,
  status_id: values.status_id,
  sale_price: values.sale_price,
  rent_price: values.rent_price,
  location_street_address: values.location_street_address,
  location_street_number: values.location_street_number,
  location_add_on_address: values.location_add_on_address,
  location_city: values.location_city,
  location_neighborhood: values.location_neighborhood,
  location_zipcode: mask(values.location_zipcode, '##.###-###'),
});

export default formatEditObject;

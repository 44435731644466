import Chance from 'chance';

const customerList = () => {
  const chance = new Chance();

  const data = Array.from({ length: 70 }, () => ({
    _id: chance.guid(),
    name: chance.name(),
    customer_client_id: chance.integer({ min: 1, max: 99999 }),
    owner: chance.bool(),
    emails: [chance.email(), chance.email()],
    phones: [chance.phone({ country: 'br' })],
  }));

  return {
    data: {
      data,
      total: data.length
    }
  }
}

export default customerList();

import React, { Fragment, useContext } from 'react';

import { Header } from '../../../components';
import SearchResult from '../components/search-result/search-result';
import Detail from '../components/details/details';

import { AppContext } from '../../../context';

const MigrationList = () => {
  const { agencyInformations } = useContext(AppContext);

  return (
    <Fragment>
      <Header title={agencyInformations.name} />

      <Detail />
      <SearchResult />
    </Fragment>
  )
}

export default MigrationList;

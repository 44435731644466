import React from 'react';
import { Box } from 'grommet';

const TableWrap = ({ children }) => {
  return (
    <Box
      round='small'
      border={{ color: 'light-4' }}
      background='white'
      elevation='xsmall'
      width='100vw'
    >
      {children}
    </Box>
  )
}

export default TableWrap;

import React, { useContext, Fragment } from 'react';
import { Box, Layer } from 'grommet';

import { customerDetail } from '../../../../api';
import { formatDatetime, mask, fillZipcode, formatNumber } from '../../../../commons';
import {
  Placeholder,
  Loading,
  Container,
  Title,
  SubTitle,
  Word,
  Wrapper,
  Separator,
  Description,
  TimelineList,
} from '../../../../components';

import { AppContext } from '../../../../context';
import { useFetchDetail, useCloseLayer } from '../../../../hooks';

import { layers, messageDictionary } from '../../../../constants';

const CustomerDetail = () => {
  const { close } = useCloseLayer();
  const { layerVisible } = useContext(AppContext);

  const { loading, detailData, requestError, retryRequest } = useFetchDetail({
    get: customerDetail,
    layerType: layers.detail
  });

  if(!layerVisible || layerVisible !== layers.detail)
    return null;

  return (
    <Layer
      responsive={false}
      full='vertical'
      position='left'
      onClickOutside={close}
      onEsc={close}
    >
      <Box
        fill='vertical'
        width='60vw'
        overflow='auto'
        flex
      >
        {requestError ? (
          <Placeholder
            title='Ops.'
            message={messageDictionary.requestFail}
            button={{
              label: 'Tentar novamente',
              action: retryRequest,
            }}
          />
        ) : null}

        {loading && !requestError ? (<Loading />) : null}

        {!loading && !requestError ? (
          <Container>
            <Title value='Detalhes do cliente' />

            <Wrapper>
              <Word title='Nome' text={detailData.name} />
              <Word title='Origem' text={detailData.customer_media} />
              <Word title='Proprietário?' text={detailData.owner ? 'Sim' : 'Não'} />
              <Word title='Ativo?' text={detailData.enable ? 'Sim' : 'Não'} />
            </Wrapper>

            <Separator />

            <SubTitle text='Telefone(s)' />

            <Wrapper>
              {detailData.phones && detailData.phones.length > 0 &&
                detailData.phones.map((item, index) => (
                  <Word
                    key={index}
                    title={item.phone_type}
                    text={item.phone}
                  />
                ))
              }
            </Wrapper>

            <Separator />

            <SubTitle text='E-mail(s)' />

            <Wrapper>
              {detailData.emails && detailData.emails.length > 0 &&
                detailData.emails.map((email, index) => (
                  <Word
                    key={index}
                    title='E-mail'
                    text={email}
                    truncateValue={true}
                  />
                ))
              }
            </Wrapper>

            <Separator />

            <SubTitle text='Usuários vinculados' />

            <Wrapper>
              {detailData.services && detailData.services.length > 0 &&
                detailData.services.map((user, index) => (
                  <Word
                    key={index}
                    title={`Atende como ${user.customer_type}`}
                    text={user.broker}
                  />
                ))
              }
            </Wrapper>

            <Separator />

            <SubTitle text='Dados Gerais' />

            <Wrapper>
              <Word title='Data de nascimento' text={formatDatetime(detailData.born_date)} />
              <Word title='Gênero' text={detailData.gender} />
              <Word title='Tipo de pessoa' text={detailData.person_type} />
              <Word title='RG' text={detailData.identification_card} />
              <Word title='CPF' text={detailData.social_security_number} />
              <Word title='Nacionalidade' text={detailData.nationality} />
              <Word title='Profissão' text={detailData.job} />
              <Word title='Estado civil' text={detailData.marital_status} />
              <Word title='Renda' text={formatNumber(detailData.income, 'currency', 2)} />
              <Word title='Escolaridade' text={detailData.education_level} />
              <Word title='Data de cadastro' text={formatDatetime(detailData.created_date)} />
              <Word title='Última atualização' text={formatDatetime(detailData.updated_date)} />
            </Wrapper>

            <Description
              title='Observações'
              text={detailData.observations}
            />

            <Separator />

            <SubTitle text='Endereço' />

            <Wrapper>
              <Word title='Logradouro' text={detailData.location_street_address} />
              <Word title='Número' text={detailData.location_street_number} />
              <Word title='Bairro' text={detailData.location_neighborhood} />
              <Word title='Cidade' text={detailData.location_city} />
              <Word title='Estado' text={detailData.location_state} />
              <Word title='CEP' text={mask(fillZipcode(detailData.location_zipcode), '##.###-###')} />
            </Wrapper>

            <Separator />

            <SubTitle text='Cônjuge' />

            <Wrapper>
              <Word title='Nome' text={detailData.partner_name} />
              <Word title='E-mail' text={detailData.partner_email} />
              <Word title='CPF' text={detailData.partner_social_security_number} />
              <Word title='RG' text={detailData.partner_identification_card} />
              <Word title='Data de nascimento' text={detailData.born_date_partner} />
            </Wrapper>

            {detailData.followup && detailData.followup.length > 0 && (
              <Fragment>
                <Separator />
                <SubTitle text='Atendimentos' />

                <TimelineList
                  list={
                    detailData.followup.map(a => ({ description: a.description }))
                  }
                />
              </Fragment>
            )}
          </Container>
        ) : null}
      </Box>
    </Layer>
  );
}

export default CustomerDetail;

const entityMigrationStatus = {
  data: {
    brokers: true,
    customers: false,
    enterprises: true,
    properties: true,
  }
}

export default entityMigrationStatus;

import React, { useState, useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Box,
  Text,
  TextInput,
  Button,
  Keyboard,
} from 'grommet';

import { AppContext } from '../../../context';
import { postAuthenticate } from '../../../api';
import { Alert } from '../../../components';

import urlRoutes from '../../../config/url-routes';
import logoKenlo from '../../../imgs/kenlo2.png';

import { getItemStorage, setItemStorage } from '../../../commons';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState(false);
  const [message, setMessage] = useState('Informe o campo e-mail e senha.');

  const context = useContext(AppContext);
  const history = useHistory();

  const handleInputChange = (event) => {
    const { value, name } = event.target;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  const handleSubmit = async () => {
    if (!email || !password) {
      setErro(true);
      return false;
    }

    setLoading(true);

    try {
      const { data } = await postAuthenticate({
        email,
        password,
      });

      setItemStorage(process.env.REACT_APP_TOKEN_AUTH_NAME, data.token);
      context.loadUserData();
      history.push(urlRoutes.migrationsUrl);
    } catch (error) {
      setErro(true);
      setMessage('E-mail ou senha não conferem');
    } finally {
      setLoading(false);
    }
  };

  if (context.authenticated) {
    return <Redirect to={urlRoutes.migrationsUrl} />;
  }

  if (!context.agencyInformations && getItemStorage(process.env.REACT_APP_TOKEN_AUTH_NAME)) {
    return null;
  }

  return (
    <div className='login-container'>
      <div className='login-left'>
        <div className='login-image'></div>
      </div>
      <div className='login-right'>
      <Box align='start-left'>
      </Box>
        <Box
          width='larger'
          align='center'
        >
          <img
            src={logoKenlo}
            alt='Logo Kenlo'
            width='300px'
          />
          <Text
            size='larger'
            color='#5b6169'
            margin={{ bottom: 'xxsmall' }}
          >
            Base Homologação
          </Text>
          <Box
            gap='medium'
            fill='horizontal'
            margin={{ vertical: 'medium' }}
          >
            <Box
              round='xsmall'
              background='#F2F2F2'  // Adicione esta linha para definir o fundo
              pad={{
                vertical: 'xsmall',
                horizontal: 'larger'
              }}
            >
              <TextInput
                plain
                name='email'
                type='email'
                placeholder={<Text color='#888888'>E-mail</Text>}
                onChange={handleInputChange}
                value={email}
              />
            </Box>


            <Box
              round='xsmall'
              background='#F2F2F2'
              pad={{
                vertical: 'xsmall',
                horizontal: 'larger'
              }}
            >
              <Keyboard
                onEnter={handleSubmit}
              >
                <TextInput
                  plain
                  name='password'
                  type='password'
                  placeholder={<Text color='#888888'>Senha</Text>}
                  onChange={handleInputChange}
                  value={password}
                />
              </Keyboard>
            </Box>
          </Box>

          {erro && <Alert alertType='error' message={message} />}

          <Button
            primary
            fill='horizontal'
            color='#F82E52'
            size='large'
            label={!loading ? (
              <Text color='white'>ENTRAR</Text>
            ) : (
              <Text color='white'>AUTENTICANDO...</Text>
            )}
            disabled={loading}
            onClick={handleSubmit}
          />
        </Box>
      </div>
    </div>
  );
};

export default Login;

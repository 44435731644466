export default (value, formattingOptions) => {
  if(value === undefined || value === null || !value)
    return value;

  const locale = 'pt-BR';
  const dateObject = new Date(value);
  const defaultOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    //hour: 'numeric',
    //minute: 'numeric',
    ...formattingOptions,
  }

  return new Intl.DateTimeFormat(locale, defaultOptions).format(dateObject);
}

import React from 'react';
import { Text } from 'grommet';

const TitleField = ({ text, required }) => (
  <Text size='small' color='dark-3'>
    {text} {required ? '*' : ''}
  </Text>
)

export default TitleField;

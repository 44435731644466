const preprocessorSend = (values) => ({
  _id: values._id,
  name: values.name,
  website: values.website,
  administration: values.administration,
  status_id: values.status_id,
  location_street_address: values.location_street_address,
  location_street_number: values.location_street_number,
  location_city: values.location_city,
  location_neighborhood: values.location_neighborhood,
  location_zipcode: values.location_zipcode.replace(/[^0-9]/g, ''),
});

export default preprocessorSend;

import React from 'react';
import { ResponsiveContext, Box } from 'grommet';

const TableRow = ({ children }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          align='center'
          direction='row'
          pad={{
            horizontal: size === 'small' ? 'large' : 'medium',
            vertical: size === 'small' ? 'medium' : 'small'
          }}
          border={{
            side: 'bottom',
            color: 'light-3',
          }}
          justify='between'
          gap='medium'
        >
          {children}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )

}

export default TableRow;

export default (value) => {
  if(!value) {
    return null
  }

  const {
    groups: {
      day,
      month,
      year
    }
  } = /(?<day>[0-9]{1,2})\/(?<month>[0-9]{1,2})\/(?<year>[0-9]{4})/.exec(value);

  return new Date(year, month - 1, day);
}

import React, { Fragment, useContext } from 'react';

import { Header } from '../../../components';
import FormApproveMigration from '../components/approve/approve';

import { AppContext } from '../../../context';

const ChangeMigrationApproveStatus = () => {
  const { agencyInformations } = useContext(AppContext);

  return (
    <Fragment>
      <Header title={agencyInformations.name} />
      <FormApproveMigration />
    </Fragment>
  )
}

export default ChangeMigrationApproveStatus;

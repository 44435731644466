import React, { useContext, useEffect, useCallback } from 'react';

import { AppContext } from '../../../context';

import urlRoutes from '../../../config/url-routes';

const Logout = () => {
  const { logout } = useContext(AppContext);

  const handlerLogoutSuccess = useCallback(() => {
    window.location = urlRoutes.login;
  }, []); 

  useEffect(() => {
    logout();
    handlerLogoutSuccess();
  }, [logout, handlerLogoutSuccess]);

  return (
    <div>Saindo...</div>
  );
}

export default Logout;

const customerDetail = {
  data: {
    _id: '345wrg5363rget7ebe5726d',
    customer_client_id: '345',
    name: 'Rodrigo',
    born_date: null,
    job: 'Eletricista',

    identification_card: '40.589.697-58',
    social_security_number: '815.698.888-98',
    person_type: 'Pessoa Física',
    person_type_id: 1,
    nationality: 'Brasileira',
    education_level: 'Superior Completo',
    education_level_id: 3,
    gender: 'Masculino',
    gender_id: 1,
    marital_status: 'Solteiro',
    marital_status_id: 2,
    customer_media_id: 11,
    emails: [
      'antonio.leme@ingaia.com.br',
      'antonio@ingaia.com.br',
    ],
    followup: [
      {
          broker: null,
          service_date: '1753-01-01T02:00:00.000Z',
          description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestias aut, repellat ipsum facere voluptate dicta obcaecati deserunt nobis suscipit eaque?'
      },
      {
        broker: 'Maria Ferreira',
        service_date: '1753-01-01T02:00:00.000Z',
        description: 'Lorem, ipsum dolor sit a deserunt nobis suscipit eaque?'
    }
    ],
    phones: [
      {
        phone: '(12) 2258-6669',
        phone_type: 'Residencial',
        phone_type_id: 1,
      },
      {
        phone: '(12) 9889-02234',
        phone_type: 'Celular',
        phone_type_id: 3,
      }
    ],
    partner_name: 'Fernanda',
    partner_email: 'fer@gmail.com',
    partner_social_security_number: '889.365.279-28',
    partner_identification_card: '00.987.987-98',
    born_date_partner: null,
    income: null,
    enable: true,
    owner: true,
    customer_media: 'Zap',
    services: [{
      customer_type: 'Interessado',
      broker: 'Jorge'
    }],
    created_date: '2021-06-04T12:00:00Z',
    updated_date: '2021-06-04T12:00:00Z',
    location_street_address: 'Rua Pedro Gomes',
    location_street_number: 229,
    location_neighborhood: 'Remanso Campineiro',
    location_city: 'Hortolândia',
    location_state: 'SP',
    location_zipcode: '13.345-908',
    observations: 'bla bla bla',
  }
}

export default customerDetail;

import React, { Fragment } from 'react';
import { Box, Text } from 'grommet';

const ListLevel = ({ title, value, subList }) => {
  return (
    <Fragment>
      <Box
        direction='row'

        pad={{ vertical: 'small' }}
        justify='between'
      >
        <Text
          size='medium'
          color='dark-2'
          textAlign='start'
        >
          {title}
        </Text>

        <Text
          size='medium'
          color='dark-3'
          textAlign='start'
        >
          {value}
        </Text>
      </Box>

      <Box>
        {subList && subList.map((a, index) => (
          <Box
            key={index}
            direction='row'
            pad={{ vertical: 'xxsmall' }}
            justify='between'
          >
            <Text
              size='small'
              color='dark-4'
              textAlign='start'
            >
              {a.label}
            </Text>

            <Text
              size='small'
              color='dark-3'
              textAlign='start'
            >
              {a.value}
            </Text>
          </Box>
        ))}
      </Box>
    </Fragment>
  )
}

export default ListLevel;

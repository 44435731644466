const urlRoutes = ({
  login: '/login',
  logout: '/logout',

  usersUrl: '/migrations/:migrationId/users',
  customersUrl: '/migrations/:migrationId/customers',
  propertiesUrl: '/migrations/:migrationId/properties',
  enterprisesUrl: '/migrations/:migrationId/enterprises',

  changeMigrationRefuseStatusUrl: '/migrations/:migrationId/refuse',
  changeMigrationApproveStatusUrl: '/migrations/:migrationId/approve',
  migrationsUrl: '/migrations',
});

export default urlRoutes;

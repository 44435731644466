import React from 'react';
import Routes from '../routes/routes';
import { Grommet } from 'grommet';
import AppContextProvider from '../../context';
import Comunicator from '../../comunicator';

import customThemeBrand from '../../config/custom-theme-brand';

export default function App() {
  return (
    <Grommet theme={customThemeBrand}>
      <AppContextProvider>
        <Routes />
        <Comunicator />
      </AppContextProvider>
    </Grommet>
  )
}

import React, { Fragment } from 'react';
import { Carousel, Box, Image } from 'grommet';
import Badge from './badge';

const ImageList = ({ list }) => {
  return (

    <Box
      height='medium'
      overflow='hidden'
      fill='horizontal'
      pad={{ vertical: 'xsmall', horizontal: 'xsmall' }}
      border='all'
      round='xsmall'
      flex={{ shrink: 0 }}
    >
      <Carousel
        fill
        controls='arrows'
      >
        {list
          .sort((a, b) => a.order - b.order)
          .map((item, index) => (
            <Fragment key={index}>
              <Image fit='cover' src={item.url} />

              <Box
                pad={{ vertical: 'small' }}
              >
                <Box
                  direction='row'
                  gap='small'
                >
                  <Badge text={`${index + 1} de ${list.length}`} />

                  {item.website && (<Badge text='Publicado no site' />)}
                  {item.highlight && (<Badge text='Principal' />)}
                </Box>
              </Box>
            </Fragment>
          )
        )}
      </Carousel>
    </Box>
  )
}

export default ImageList;

const saveAs = (list, name) => {
  const csv = '\uFEFF' + list;
  const blob = window.URL.createObjectURL(new Blob([csv]));

  const link = document.createElement('a');

  link.download = name;
  link.href = blob;
  link.rel = 'noopener';

  link.dispatchEvent(new MouseEvent('click'));
}

export default saveAs;

import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';

const Title = ({ value }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{ bottom: 'medium' }}
        >
          <Text
            size={ size === 'small' ? 'large' : 'xlarge' }
            textAlign='start'
            color='dark-1'
          >
            {value}
          </Text>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Title;

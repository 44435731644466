import React from 'react';
import { Grid } from 'grommet';

const Wrapper = ({ children }) => (
  <Grid
    columns={{
      count: 3,
      size: 'small',
    }}
    justifyContent='around'
    gap={{
      row: 'medium',
      column: 'small'
    }}
  >
    {children}
  </Grid>
)

export default Wrapper;

import React, { useContext } from 'react';
import { Box, Layer } from 'grommet';

import { formatNumber } from '../../../../commons';
import { Placeholder, Loading, PlainCard, Container, Title, ListLevel, Wrapper, Separator } from '../../../../components';

import { AppContext } from '../../../../context';
import { useCloseLayer, useMigrationSummary } from '../../../../hooks';

import { layers, messageDictionary } from '../../../../constants';

const MigrationDetail = () => {
  const { close } = useCloseLayer();
  const { layerOpenedId, layerVisible } = useContext(AppContext);

  const { loading, data, requestError } = useMigrationSummary(layerOpenedId);

  if(layerVisible !== layers.detail)
    return null;

  return (
    <Layer
      responsive={false}
      full='vertical'
      position='left'
      onClickOutside={close}
      onEsc={close}
    >
      <Box
        fill='vertical'
        width='60vw'
        overflow='auto'
        flex
      >
        {requestError ? (
          <Placeholder
            title='Ops.'
            message={messageDictionary.requestFail}
            button={{
              label: 'Fechar',
              action: close,
            }}
          />
        ) : null}

        {loading && !requestError ? (<Loading />) : null}

        {!loading && !requestError ? (
          <Container>
            <Title value='Total de dados registrados' />

            <Wrapper>
              <PlainCard
                title='Usuários'
                listKeyValue={[
                  { key: formatNumber(data.brokers?.total), value: 'aprovado(s)', color: '#BE8244' },
                  { key: formatNumber(data.brokers?.total), value: 'recebido(s)', color: '#BE8244' },
                ]}
              />

              <PlainCard
                title='Clientes'
                listKeyValue={[
                  { key: formatNumber(data.customers?.total), value: 'aprovado(s)', color: '#BFBC54' },
                  { key: formatNumber(data.customers?.total), value: 'recebido(s)', color: '#BFBC54' },
                ]}
              />

              <PlainCard
                title='Imóveis'
                listKeyValue={[
                  { key: formatNumber(data.properties?.accepted), value: 'aprovado(s)', color: '#3CB185' },
                  { key: formatNumber(data.properties?.total), value: 'recebido(s)', color: '#3CB185' },
                ]}
              />
            </Wrapper>

            <Wrapper>
              <PlainCard
                title='Fotos imóveis'
                listKeyValue={[
                  { key: formatNumber(data.properties?.photos), value: 'aprovado(s)', color: '#3CB185' },
                  { key: formatNumber(data.properties?.photos), value: 'recebido(s)', color: '#3CB185' },
                ]}
              />

              <PlainCard
                title='Arq. imóveis'
                listKeyValue={[
                  { key: formatNumber(data.properties?.archives), value: 'aprovado(s)', color: '#3CB185' },
                  { key: formatNumber(data.properties?.archives), value: 'recebido(s)', color: '#3CB185' },
                ]}
              />

              <PlainCard
                title='Empreend.'
                listKeyValue={[
                  { key: formatNumber(data.enterprises?.accepted), value: 'aprovado(s)', color: '#00A2C2' },
                  { key: formatNumber(data.enterprises?.total), value: 'recebido(s)', color: '#00A2C2' },
                ]}
              />
            </Wrapper>

            <Wrapper>
              <PlainCard
                title='Fotos empreend.'
                listKeyValue={[
                  { key: formatNumber(data.enterprises?.photos), value: 'aprovado(s)', color: '#00A2C2' },
                  { key: formatNumber(data.enterprises?.photos), value: 'recebido(s)', color: '#00A2C2' },
                ]}
              />

              <PlainCard
                title='Arq. empreend.'
                listKeyValue={[
                  { key: formatNumber(data.enterprises?.archives), value: 'aprovado(s)', color: '#00A2C2' },
                  { key: formatNumber(data.enterprises?.archives), value: 'recebido(s)', color: '#00A2C2' },
                ]}
              />
            </Wrapper>

            <ListLevel
              title = 'Detalhes dos imóveis'
              value = {formatNumber(data.properties?.total)}
              subList = {[
                {
                  label: 'Sem corretor (captador, indicação, promotor, fotografia)',
                  value: formatNumber(data.properties?.no_broker),
                },
                {
                  label: 'Sem tipo (casa, apartamento, etc.)',
                  value: formatNumber(data.properties?.no_type),
                },
                {
                  label: 'Sem preço (venda/locação)',
                  value: formatNumber(data.properties?.no_price),
                },
                {
                  label: 'Sem área (total, útil/construída, comum, privativa)',
                  value: formatNumber(data.properties?.no_area),
                },
                {
                  label: 'Sem complemento no endereço (imóveis verticais: apartamento, cobertura, kitnet, etc.)',
                  value: formatNumber(data.properties?.vertical_without_add_on_address),
                },
                {
                  label: 'Sem proprietário',
                  value: formatNumber(data.properties?.no_owners),
                },
                {
                  label: 'Sem endereço/CEP',
                  value: formatNumber(data.properties?.no_zipcode),
                },
                {
                  label: 'Sem empreendimento (imóveis verticais: apartamento, cobertura, kitnet, etc.)',
                  value: formatNumber(data.properties?.vertical_without_enterprise),
                }
              ]}
            />

            <Separator />

            <ListLevel
              title = 'Total de clientes'
              value = {formatNumber(data.customers?.total)}
              subList = {[
                {
                  label: 'Sem e-mails',
                  value: formatNumber(data.customers?.no_emails),
                },
                {
                  label: 'Sem telefone',
                  value: formatNumber(data.customers?.no_phones),
                },
                {
                  label: 'Sem corretor',
                  value: formatNumber(data.customers?.no_broker),
                },
              ]}
            />

            <Separator />

            <ListLevel
              title = 'Total de empreendimentos'
              value = {formatNumber(data.enterprises?.total)}
              subList = {[
                {
                  label: 'Sem endereço/CEP',
                  value: formatNumber(data.enterprises?.no_zipcode),
                },
                {
                  label: 'Sem foto',
                  value: formatNumber(data.enterprises?.no_photo),
                },
              ]}
            />

            <Separator />

            <ListLevel
              title = 'Total de usuários'
              value = {formatNumber(data.brokers?.total)}
              subList = {[
                {
                  label: 'Sem e-mail',
                  value: formatNumber(data.brokers?.no_email),
                },
                {
                  label: 'Sem perfil',
                  value: formatNumber(data.brokers?.no_profile),
                },
              ]}
            />
          </Container>
        ) : null}
      </Box>
    </Layer>
  );
}

export default MigrationDetail;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { getMigrationList } from '../../../../api';
import { PageWrapper, Badge } from '../../../../components';
import { TableHeader, TableMessages, TableWrap, TableRow, TableCols, TableActionsCol } from '../../../../components/listing';
import { formatDatetime } from '../../../../commons';

import { useOpenLayer, useFetchListing } from '../../../../hooks';

import urlRoutes from '../../../../config/url-routes';

import { layers, migrationStatus, messageDictionary } from '../../../../constants';

const SearchResult = () => {
  const { push } = useHistory();
  const { open } = useOpenLayer();

  const { loading, list, requestError } = useFetchListing({ get: getMigrationList });

  return (
    <PageWrapper>
      <TableWrap>
        <TableHeader title='Acompanhe suas migrações' totalItens={list.total} />

        {requestError && (
          <TableMessages message={messageDictionary.requestFail} />
        )}

        {loading && !requestError && (
          <TableMessages message={messageDictionary.waitingResponseRequest} />
        )}

        {!loading && !requestError && list.total <= 0 && (<TableMessages />)}

        {!loading && !requestError && list.data.map((row, index) => (
          <TableRow key={index}>
            <TableCols
              columns={[
                { value: row.identifier, width: 'small', textSize: 'medium' },
                {
                  width: 'small',
                  textSize: 'small',
                  value: formatDatetime(row.created_at)
                },
                {
                  width: 'medium',
                  value: <Badge text={row.status} />
                }
              ]}
            />

            {row.status_enum === migrationStatus.environmentReadyForAnalisys ? (
              <TableActionsCol
                actions={[
                  {
                    label: 'resumo',
                    action: () => open({ entityIdentifier: row._id, layer: layers.detail }),
                  },
                  {
                    label: 'acompanhar',
                    action: () => push(urlRoutes.usersUrl.replace(/:migrationId/gi, row._id)),
                  },
                ]}
              />
            ) : (
              row.status_enum !== migrationStatus.environmentCreated &&
                <TableActionsCol
                  actions={[
                    {
                      label: 'resumo',
                      action: () => open({ entityIdentifier: row._id, layer: layers.detail }),
                    }
                  ]}
                />
            )}
          </TableRow>
        ))}
      </TableWrap>
    </PageWrapper>
  )
}

export default SearchResult;

import React, { useContext, Fragment } from 'react';

import { Header } from '../../../components';
import SearchResult from '../components/search-result/search-result';
import Edit from '../components/edit/edit';
import Detail from '../components/details/details';

import { AppContext } from '../../../context';

const CustomerList = () => {
  const { agencyInformations } = useContext(AppContext);

  return (
    <Fragment>
      <Header title={agencyInformations.name} />

      <Edit />
      <Detail />
      <SearchResult />
    </Fragment>
  )
}

export default CustomerList;

const list = [
  { name: 'Residencial', id: '1' },
  { name: 'Comercial', id: '2' },
  { name: 'Industrial', id: '3' },
  { name: 'Rural', id: '4' },
  { name: 'Temporada', id: '5' },
  { name: 'Corporativa', id: '6' },
];

export default list;

import Chance from 'chance';

const enterpriseList = () => {
  const chance = new Chance();

  const data = Array.from({ length: 40 }, () => ({
    _id: chance.guid(),
    name: chance.name(),
    location_city: chance.city(),
    location_state_abbreviation: chance.state(),
  }));

  return {
    data: {
      data,
      total: data.length
    }
  }
}

export default enterpriseList();

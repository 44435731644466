import React from 'react';
import { Box } from 'grommet';

const Separator = () => {
  return (
    <Box
      border={{
        side: 'bottom',
        color: 'light-4'
      }}
      margin={{ top: 'medium' }}
    />
  )
}

export default Separator;

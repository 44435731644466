import React from 'react';
import { Box } from 'grommet';

import TableCol from './column';

const TableCols = ({ columns }) => {
  return (
    <Box
      direction='row'
      gap='xxsmall'
    >
      {columns.map((item, index) => {
        const { value, ...rest } = item;

        return (
          <TableCol
            key={index}
            {...rest}
          >
            {value}
          </TableCol>
        )
      })}
    </Box>
  )
}

export default TableCols;

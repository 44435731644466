import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';

const SubTitle = ({ text }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          margin={{
            top: 'medium',
            bottom: 'xsmall'
          }}
          flex={{ shrink: 0 }}
        >
          <Text
            size={ size === 'small' ? 'small' : 'medium' }
            weight='bold'
            textAlign='start'
            color='dark-2'
          >
            {text}
          </Text>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default SubTitle;

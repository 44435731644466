import React from 'react';
import ReactDOM from 'react-dom';
import App from './main/app/app';

// import registerServiceWorker from './registerServiceWorker';
import './styles/app.scss';

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<App/>, document.getElementById('root'));
// registerServiceWorker();

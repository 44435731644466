import React from 'react';
import { ResponsiveContext, Box, Text, Button } from 'grommet';

const Placeholder = ({
  title = null,
  message = null,
  button = null
}) => (
  <ResponsiveContext.Consumer>
    {size => (
      <Box
        direction='column'
        align='center'
        justify='center'
        margin={{ top: 'medium' }}
        height='80vh'
      >
        <Box
          align='center'
          width='medium'
          pad={ size === 'small' ? 'large' : 'small' }
        >
          {title && (<Box margin={{ bottom: 'small' }}>
            <Text size='xlarge' textAlign='center'>
              {title}
            </Text>
          </Box>)}

          {message && (<Box margin={{ top: 'none', bottom: 'medium' }}>
            <Text size='small' color='dark-5' textAlign='center'>
              {message}
            </Text>
          </Box>)}

          {button && (<Box margin={{ top: 'none' }}>
              <Button
                label={button.label}
                primary={true}
                onClick={() => { button.action() }}
              />
            </Box>
          )}
        </Box>
      </Box>
    )}
  </ResponsiveContext.Consumer>
);

export default Placeholder;

import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockEnterpriseList from './fakes/enterprise-listing';

const getEnterpriseFile = (params) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockEnterpriseList), 3000);
    });
  }

  const { migrationId } = params;
  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'GET',
    url: `/migrations/${migrationId}/enterprises/download`,
  });
}

export default getEnterpriseFile;


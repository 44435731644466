import axiosGlobalInstance from './configHttpInstance/http-instance';

const edit = ({ entity, payload }) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), 1000);
    });
  }

  const { _id, ...rest } = payload;

  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'PATCH',
    url: `/${entity}/${_id}`,
    data: rest
  });
}

export default edit;


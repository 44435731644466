import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, ResponsiveContext, CheckBox, Button, Text } from 'grommet';

import { postChangeStatus } from '../../../../api';
import { formatNumber } from '../../../../commons';
import { PageWrapper, Loading, Separator, ListLevel, Alert } from '../../../../components';

import { useForm, useMigrationSummary, useEnvironmentDetail } from '../../../../hooks';

import urlRoutes from '../../../../config/url-routes';

import { migrationStatus, messageDictionary } from '../../../../constants';

const FormApproveMigration = () => {
  const { goBack, push } = useHistory();
  const { migrationId } = useParams();

  const [acceptTerm, setAcceptTerm] = useState(false);

  const { loading, data } = useMigrationSummary(migrationId, true);
  const { loading: loadingEnv, data: dataEnv } = useEnvironmentDetail(migrationId);
  const { values, errors, successful, submitting, handleSubmit, initialize } = useForm();

  useEffect(() => {
    initialize({
      migrationId,
      status: migrationStatus.finalMigrationInProgress,
    });
  }, [initialize, migrationId]); // Adiciona as dependências `initialize` e `migrationId`  

  if(loading || loadingEnv) {
    return <Loading />;
  }

  if(successful || dataEnv.status !== migrationStatus.environmentReadyForAnalisys) {
    return (
      <ResponsiveContext.Consumer>
        {size => (
          <PageWrapper>
            <Box
              round='small'
              border={{ color: 'light-4' }}
              background='white'
              elevation='small'
              width={ size === 'small' ? '100vw' : '50vw' }
              pad={{
                horizontal: size === 'small' ? 'large' : 'medium',
                vertical: size === 'small' ? 'medium' : 'small'
              }}
              margin={{ bottom: 'medium' }}
            >
              <Text size='medium' textAlign='center' color='dark-3'>
                Falta pouco! Agora estamos enviando seus dados para o ambiente de produção, aguarde e seja bem vindo ao Kenlo Imob!
              </Text>
            </Box>

            <Box
              direction='row'
              gap='small'
            >
              <Button
                color='dark-3'
                label='Ok'
                onClick={() =>
                  push(urlRoutes.migrationsUrl)
                }
              />
            </Box>
          </PageWrapper>
        )}
      </ResponsiveContext.Consumer>
    );
  }

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <PageWrapper>
          <Box
            margin={{ bottom: 'medium' }}
            width='large'
          >
            <Text
              size='large'
              color='dark-2'
              textAlign='center'
            >
              Deseja realmente APROVAR essa migração? Após aprovação iniciaremos o processo de envio dos dados para o ambiente de produção.
            </Text>
          </Box>

          <Box
            round='small'
            border={{ color: 'light-4' }}
            background='white'
            elevation='small'
            width={ size === 'small' ? '100vw' : '50vw' }
            pad={{
              horizontal: size === 'small' ? 'large' : 'medium',
              vertical: size === 'small' ? 'medium' : 'small'
            }}
            margin={{ bottom: 'medium' }}
          >
            <Fragment>
              <ListLevel
                title = 'Total de imóveis'
                value = {formatNumber(data.properties?.total)}
                subList = {[
                  {
                    label: 'Sem corretor (captador, indicação, promotor, fotografia)',
                    value: formatNumber(data.properties?.no_broker),
                  },
                  {
                    label: 'Sem tipo (casa, apartamento, etc.)',
                    value: formatNumber(data.properties?.no_type),
                  },
                  {
                    label: 'Sem preço (venda/locação)',
                    value: formatNumber(data.properties?.no_price),
                  },
                  {
                    label: 'Sem área (total, útil/construída, comum, privativa)',
                    value: formatNumber(data.properties?.no_area),
                  },
                  {
                    label: 'Sem complemento no endereço (imóveis verticais: apartamento, cobertura, kitnet, etc.)',
                    value: formatNumber(data.properties?.vertical_without_add_on_address),
                  },
                  {
                    label: 'Sem proprietário',
                    value: formatNumber(data.properties?.no_owners),
                  },
                  {
                    label: 'Sem endereço/CEP',
                    value: formatNumber(data.properties?.no_zipcode),
                    warning: true,
                    warningText: 'bla bla bla'
                  },
                  {
                    label: 'Sem empreendimento (imóveis verticais: apartamento, cobertura, kitnet, etc.)',
                    value: formatNumber(data.properties?.vertical_without_enterprise),
                    warning: true,
                    warningText: 'bla bla bla'
                  }
                ]}
              />

              <Separator />

              <ListLevel
                title = 'Total de clientes'
                value = {formatNumber(data.customers?.total)}
                subList = {[
                  {
                    label: 'Sem e-mails',
                    value: formatNumber(data.customers?.no_emails),
                  },
                  {
                    label: 'Sem telefone',
                    value: formatNumber(data.customers?.no_phones),
                  },
                  {
                    label: 'Sem corretor',
                    value: formatNumber(data.customers?.no_broker),
                  },
                ]}
              />

              <Separator />

              <ListLevel
                title = 'Total de empreendimentos'
                value = {formatNumber(data.enterprises?.total)}
                subList = {[
                  {
                    label: 'Sem endereço/CEP',
                    value: formatNumber(data.enterprises?.no_zipcode),
                  },
                  {
                    label: 'Sem foto',
                    value: formatNumber(data.enterprises?.no_photo),
                  },
                ]}
              />

              <Separator />

              <ListLevel
                title = 'Total de usuários'
                value = {formatNumber(data.brokers?.total)}
                subList = {[
                  {
                    label: 'Sem e-mail',
                    value: formatNumber(data.brokers?.no_email),
                  },
                  {
                    label: 'Sem perfil',
                    value: formatNumber(data.brokers?.no_profile),
                  },
                ]}
              />
            </Fragment>
          </Box>

          {<Box
            width='50vw'
            margin={{ bottom: 'medium' }}
            direction='row'
            gap='small'
            pad={{
              horizontal: 'small',
            }}
            align='start'
          >
            <CheckBox
              checked={acceptTerm}
              onChange={event => setAcceptTerm(event.target.checked)}
            />

            <Box gap='small'>
              <Text color='dark-3' size='medium'>
                Os dados de usuários, clientes, imóveis e empreendimentos, assim como as fotos, foram validados e estão corretos e de acordo com a base enviada para o processo de migração.
              </Text>

              <Text color='dark-3' size='larger'>
                Os imóveis sem CEP não serão migrados.
              </Text>

              <Text color='dark-3' size='medium'>
                Ao aprovar essa migração concordo com as condições descritas acima e aceito realizar a migração desses dados para meu ambiente de produção do Kenlo Imob.
              </Text>
            </Box>
          </Box>}

          {errors && (<Box width='50vw'>
            <Alert alertType='error' message={messageDictionary.requestFail} />
          </Box>)}

          <Box
            direction='row'
            gap='small'
          >
            <Button
              color='dark-3'
              label='Voltar'
              onClick={() =>
                goBack()
              }
            />

            <Button
              color='neutral-1'
              primary
              label={
                !submitting ? 'APROVAR migração' : 'APROVANDO migração...'
              }
              onClick={() =>
                handleSubmit({
                  resource: postChangeStatus(values),
                  callbackSuccess: null,
                })
              }
              disabled={!acceptTerm || submitting}
            />
          </Box>
        </PageWrapper>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default FormApproveMigration;

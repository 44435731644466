import React from 'react';
import { Box } from 'grommet';

const Container = ({ children }) => (
  <Box
    pad={{
      vertical: 'medium',
      horizontal: 'medium'
    }}
    flex={{ shrink: 0 }}
  >
    {children}
  </Box>
)

export default Container;

import React from 'react';
import { MaskedInput } from 'grommet';

const ZipcodeInput = props => {
  return (
    <MaskedInput
      placeholder='00.000-000'
      mask={[
        { length: 2, regexp: /\d/ },
        { fixed: '.' },
        { length: 3, regexp: /\d/ },
        { fixed: '-' },
        { length: 3, regexp: /\d/ }
      ]}
      {...props}
    />
  )
}

export default ZipcodeInput;

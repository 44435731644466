import React, { Fragment, useContext } from 'react';

import { Header } from '../../../components';
import FormRefuseMigration from '../components/refuse/refuse';

import { AppContext } from '../../../context';

const ChangeMigrationRefuseStatus = () => {
  const { agencyInformations } = useContext(AppContext);

  return (
    <Fragment>
      <Header title={agencyInformations.name} />
      <FormRefuseMigration />
    </Fragment>
  )
}

export default ChangeMigrationRefuseStatus;

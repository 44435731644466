import Chance from 'chance';

const userList = () => {
  const chance = new Chance();

  const data = Array.from({ length: 30 }, () => ({
    _id: chance.guid(),
    full_name: chance.name(),
    email: chance.email(),
    profile: chance.pickone(['Diretor', 'Corretor', 'Gerente']),
    created_date: chance.date({ year: 2021 }).toISOString(),
  }));

  return {
    data: {
      data,
      total: data.length
    }
  }
}

export default userList();

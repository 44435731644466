const preprocessorSend = (values) => ({
  _id: values._id,
  name: values.name,
  customer_media_id: values.customer_media_id,
  gender_id: values.gender_id,
  person_type_id: values.person_type_id,
  emails: values.emails,
  phones: values.phones.map(item =>
    ({
      phone: item.phone,
      phone_type_id: item.phone_type_id,
      no_mask_phone: item.phone.replace(/[^0-9]/g, ''),
    })
  ),
});

export default preprocessorSend;

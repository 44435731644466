import { useEffect, useState } from 'react';
import { environmentDetail } from '../api';

const useEnvironmentDetail = (environmentId) => {
  const [loading, setLoading] = useState(true);
  const [requestError, setRequestError] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if(!environmentId)
      return;

    setLoading(true);
    setRequestError(false);

    environmentDetail(environmentId)
      .then(({ data }) => setData(data))
      .catch(() => setRequestError(true))
      .finally(() => setLoading(false))
  }, [environmentId]);

  return {
    loading,
    data,
    requestError,
  }
}

export default useEnvironmentDetail;

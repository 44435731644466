import { useCallback, useContext, useEffect, useState } from 'react';
import { pagination } from '../constants';
import { AppContext } from '../context';

const useFetchListing = ({ migrationId, get }) => {
  const { forceReloadFetch } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState({ data: [], total: 0 });
  const [requestError, setListRequestError] = useState(false);
  const [page, setPage] = useState(1);

  const onNavigate = useCallback((currentPage) => setPage(currentPage));

  useEffect(() => {
    setLoading(true);
    setListRequestError(false);

    get({
      page,
      per_page: pagination.limit,
      migrationId
    })
      .then(({ data }) => setList(data))
      .catch(() => setListRequestError(true))
      .finally(() => setLoading(false))
  }, [page, forceReloadFetch]);

  return {
    loading,
    list,
    requestError,
    page,

    onNavigate,
  }
}

export default useFetchListing;

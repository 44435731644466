import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockChangeMigrationEntityStatus from './fakes/entity-change-status';

const postEntityChangeStatus = ({ migrationId, entity, status }) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockChangeMigrationEntityStatus), 500);
    });
  }

  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'POST',
    url: `/migrations/${migrationId}/entities/status`,
    data: {
      entity,
      status,
    }
  });
}

export default postEntityChangeStatus;

import axios from 'axios'
import mockAuthenticateDetail from './fakes/authenticate';

const postAuthenticate = (payload) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockAuthenticateDetail), 500);
    });
  }

  return axios({
    baseURL: process.env.REACT_APP_HOST_API,
    method: 'POST',
    url: '/authenticate',
    data: payload,
  });
}

export default postAuthenticate;

const propertyDetail = {
  data: {
    _id: 2,
    usage_id: 1,
    usage_type_id: 7,
    status_id: 2,
    property_secondary_reference: '4524',
    property_keys_place: 'Proprietário',
    usage_type: 'Casa',
    usage: 'Residencial',
    register_date: '2021-02-05T07:00:00Z',
    updated_date: '2021-02-05T07:00:00Z',
    location_street_address: 'Rua Pedro Gomes',
    location_street_number: 229,
    location_add_on_address: 'A',
    location_neighborhood: 'Remanso Campineiro',
    location_city: 'Hortolândia',
    location_state: 'SP',
    location_zipcode: 13345098,
    location_nearby: '',
    area_useful: 300,
    area_built: 127.50,
    area_common: 0,
    area_private: 0,
    property_dimension: 'Frente 10,00 | Laterais 27,00',
    for_sale: true,
    for_rent: true,
    status: 'Suspenso',
    exclusivity: true,
    exclusivity_begin_date: null,
    exclusivity_date: null,
    occupied_owner: false,
    occupied_renter: true,
    occupied_date: null,
    property_standard: 'Alto',
    property_localization: 'Boa',
    face: 'Norte',
    position: 'Frente',
    topography: 'Plano',
    sale_price: 76500,
    rent_price: 4500,
    condo_price: 250,
    max_occupants: 5,
    permitted_deal: false,
    mortgage: true,
    property_exchange: false,
    construction_year: 2017,
    construction_reform_year: 2019,
    municipal_property_tax: 560.50,
    municipal_property_tax_condition: 'Mensal',
    municipal_property_code: '345-44',
    municipal_property_electricity_code: '34532462',
    municipal_property_sanitation_code: 'RGI: 23422',
    municipal_property_number: null,
    advertising: true,
    advertising_type: 'Placa',
    advertising_solicitation_date: null,
    advertising_date: null,
    advertising_solicitation_date_end: null,
    advertissing_date_end: null,
    advertising_classification: 'Vender',
    published_on_site: true,
    highlight: false,
    youtube_media: null,

    details: [
      { label: 'Piscina', value: true },
      { label: 'Quadra de Tenis', value: true },
      { label: 'Água', value: true },
      { label: 'Área de serviço', value: 2 },
      { label: 'Armário área de Serviço', value: true },
      { label: 'Armário banheiro', value: 1 },
      { label: 'Taco de madeira', value: true },
      { label: 'Varanda', value: false }
    ],

    property_description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.',
    property_description_midia: null,
    property_documentation: null,
    property_commercial_condition: 'Aceita troca e mais um parcelamento',
    property_general_description: null,

    customer_migration_name: 'Carlos Roberto',
    enterprise_migration_name: 'Condomínio Terras do Lago II',

    brokers: [{
      comission_type: 'Captador',
      name: 'Jorge'
    },
    {
      comission_type: 'Fotografia',
      name: 'Fabiano Costa'
    },
    {
      comission_type: 'Fotografia',
      name: 'Antonio Leme Junior'
    },
    {
      comission_type: 'Fotografia',
      name: 'Jorge Justo'
    }],

    photos: [
      {
        full_path: 'https://artifacts-bucket.s3.region.amazonaws.com/photos/1/property/file-name.jpg',
        original_photo_path: 'photos/1/property/file-name.jpg',
        in_order: 1,
        highlight: true,
        website: true
      },
      {
        full_path: 'https://artifacts-bucket.s3.region.amazonaws.com/photos/1/property/file-name.jpg',
        original_photo_path: 'photos/1/property/file-name.jpg',
        in_order: 2,
        highlight: null,
        website: true
      },
    ],

    archives: [
      {
        full_path: 'https://artifacts-bucket.s3.region.amazonaws.com/files/1/property/file-name.pdf',
        archive_name: 'nome do arquivo em forma de sentença beeeem grandenome do arquivo em forma de sentença beeeem grandenome do arquivo em forma de sentença beeeem grande.pdf',
        original_archive_path: 'files/1/property/file-name.pdf',
      },
      {
        full_path: 'https://artifacts-bucket.s3.region.amazonaws.com/files/1/property/file-name.pdf',
        archive_name: 'file-name.pdf',
        original_archive_path: 'files/1/property/file-name.pdf',
      },
    ],
  }
}

export default propertyDetail;

import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockUserList from './fakes/user-listing';

const getUserList = (params) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockUserList), 500);
    });
  }

  const { migrationId, ...rest } = params;

  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'GET',
    url: `/migrations/${migrationId}/users`,
    params: rest
  });
}

export default getUserList;


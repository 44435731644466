import Chance from 'chance';

const migrationList = () => {
  const chance = new Chance();

  const data = Array.from({ length: 6 }, () => ({
    _id: chance.guid(),
    identifier: chance.string({ length: 5, casing: 'upper', symbols: false }),
    created_at: chance.date({ year: 2021 }).toISOString(),
    status: chance.pickone(['Ambiente pronto para migrar', 'Análise recusada', 'Migração final em andamento']),
    status_enum: chance.pickone(['environment_created', 'environment_ready_for_analysis', 'analysis_refused', 'final_migration_in_progress']),
  }));

  return {
    data: {
      data,
      total: data.length
    }
  }
}

export default migrationList();

const list = [
  { id: '1', name: 'Ativo' },
  { id: '2', name: 'Suspenso' },
  { id: '3', name: 'Vendido' },
  { id: '5', name: 'Ficha' },
  { id: '7', name: 'Cancelado' },
  { id: '8', name: 'Locado' },
  { id: '9', name: 'Provisório' },
];

export default list;

import Chance from 'chance';

const detail = () => {
  const chance = new Chance();

  return {
    data: {
      _id: chance.guid(),
      agency_migration_id: chance.guid(),
      jira_id: 'MIGRA-10897',
      status: chance.pickone(['environment_created', 'environment_ready_for_analysis', 'analysis_refused', 'final_migration_in_progress']),
      started_at: null,
      finished_at: null,
      createdAt: chance.date({ year: 2021 }).toISOString(),
      updatedAt: chance.date({ year: 2021 }).toISOString(),
    }
  }
}

export default detail();

import React from 'react';
import { Box, Text } from 'grommet';

const BadgeList = ({ list }) => {
  return (
    <Box
      direction='row'
      width='large'
      gap='small'
      wrap={true}
      pad={{ vertical: 'small' }}
    >
      {
        list.map((item, index) => (
          <Box
            key={index}
            round='large'
            background={{
              color: 'light-1'
            }}
            pad={{
              vertical: 'xsmall',
              horizontal: 'small'
            }}
            margin={{ bottom: 'small' }}
            border={{ color: 'light-3' }}
          >
            <Text size='small' color='dark-1' >
              {item}
            </Text>
          </Box>
        ))
      }
    </Box>
  )
}

export default BadgeList;

import React, { useContext, useEffect} from 'react';
import { Box, Layer, TextInput, Anchor, Button, Select } from 'grommet';

import { enterpriseDetail, edit } from '../../../../api';

import { Placeholder, Loading, Container, Title, Alert, SubTitle } from '../../../../components';
import { TitleField, ZipcodeInput } from '../../../../components/form';

import { AppContext } from '../../../../context';
import { useForm, useFetchDetail, useCloseLayer } from '../../../../hooks';

import { preprocessorSend, formatEditObject } from '../../utils';

import { layers, entities, enterpriseStatus, messageDictionary } from '../../../../constants';

const EnterpriseEdit = () => {
  const { close } = useCloseLayer();
  const { layerVisible, reloadFetch } = useContext(AppContext);

  const { loading, detailData, requestError, retryRequest } = useFetchDetail({
    get: enterpriseDetail,
    layerType: layers.edit
  });

  const { values, errors, pristine, submitting, handleSubmit, changeValues, initialize, resetEvents } = useForm();

  useEffect(() => {
    if (!layerVisible || !detailData) return;
  
    initialize(formatEditObject(detailData));
  }, [layerVisible, detailData, initialize]); 

  if(!layerVisible || layerVisible !== layers.edit)
    return null;

  return (
    <Layer
      responsive={false}
      full='vertical'
      position='left'
      onClickOutside={close}
      onEsc={close}
    >
      <Box
        fill='vertical'
        width='60vw'
        overflow='auto'
        flex
      >
        {requestError ? (
          <Placeholder
            title='Ops.'
            message={messageDictionary.requestFail}
            button={{
              label: 'Tentar novamente',
              action: retryRequest,
            }}
          />
        ) : null}

        {loading && !requestError ? (<Loading />) : null}

        {!loading && !requestError ? (
          <Container>
            <Title value='Editar empreendimento' />


            {/* nome */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box width='large' fill='horizontal'>
                <TitleField text='Nome' required />

                <TextInput
                  maxLength={120}
                  value={values.name ?? ''}
                  name='name'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            {/* hotsite e administradora */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box width='large'>
                <TitleField text='Hotsite' />

                <TextInput
                  maxLength={120}
                  value={values.website ?? ''}
                  name='website'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>

              <Box width='large'>
                <TitleField text='Administradora' />

                <TextInput
                  maxLength={60}
                  value={values.administration ?? ''}
                  name='administration'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            {/* status comercial */}
            <Box
              direction='row'
              gap='small'
              margin={{ bottom: 'small' }}
            >
              <Box width='large' fill='horizontal'>
                <TitleField text='Status comercial' required />

                <Select
                  options={enterpriseStatus}
                  value={values.status_id?.toString() ?? ''}
                  labelKey='name'
                  valueKey={{
                    key: 'id',
                    reduce: true,
                  }}
                  name='status_id'
                  emptySearchMessage='Selecione uma opção'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            <SubTitle text='Endereço' />

            <Alert
              alertType='warning'
              message={messageDictionary.warningZipcode}
            />

            {/* logradouro e número */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box width='large'>
                <TitleField text='Logradouro' required />

                <TextInput
                  maxLength={200}
                  value={values.location_street_address ?? ''}
                  name='location_street_address'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>

              <Box width='medium'>
                <TitleField text='Número' required />

                <TextInput
                  type='number'
                  maxLength={5}
                  min={0}
                  value={values.location_street_number ?? ''}
                  name='location_street_number'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


             {/* cidade */}
             <Box
              direction='row'
              gap='small'
              margin={{ bottom: 'small' }}
            >
              <Box width='large' fill='horizontal'>
                <TitleField text='Cidade' required />

                <TextInput
                  maxLength={200}
                  value={values.location_city ?? ''}
                  name='location_city'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


             {/* bairro */}
             <Box
              direction='row'
              gap='small'
              margin={{ bottom: 'small' }}
            >
              <Box width='large' fill='horizontal'>
                <TitleField text='Bairro' required />

                <TextInput
                  maxLength={200}
                  value={values.location_neighborhood ?? ''}
                  name='location_neighborhood'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            {/* cep */}
            <Box
              direction='row'
              gap='small'
              margin={{ bottom: 'small' }}
            >
              <Box width='large' fill='horizontal'>
                <TitleField text='CEP' required />

                <ZipcodeInput
                  value={values.location_zipcode ?? ''}
                  name='location_zipcode'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            {errors && <Alert alertType='error' message={messageDictionary.requestFail} />}

            {/* botões */}
            <Box
              direction='row'
              pad={{ vertical: 'medium' }}
              justify='center'
              align='center'
              gap='small'
            >
              <Box width='xsmall'>
                <Anchor
                  color='dark-3'
                  label='Cancelar'
                  onClick={() => {
                    close();
                    resetEvents();
                  }}
                />
              </Box>

              <Box width='small'>
                <Button
                  color='brand'
                  primary
                  label={!submitting ? 'Atualizar' : 'Atualizando...'}
                  disabled={pristine || submitting}
                  onClick={() =>
                    handleSubmit({
                      resource: edit({ entity: entities.enterprise, payload: preprocessorSend(values) }),
                      callbackSuccess: () =>
                        new Promise(
                          () => setTimeout(() => {
                            close();
                            reloadFetch();
                          }, 100)
                        )
                    })
                  }
                />
              </Box>
            </Box>

          </Container>
        ) : null}
      </Box>
    </Layer>
  );
}

export default EnterpriseEdit;

import React, { isValidElement } from 'react';
import { Box, Text } from 'grommet';

const TableCol = (
  {
    width = 'xsmall',
    color = 'dark-3',
    textSize = 'small',
    children
  }) => {
  return (
    <Box
      width={width}
      align='start'
    >
      {isValidElement(children) ? (
          children
        ) : (
          <Text
            color={color}
            size={textSize}
            truncate
            title={children}
          >
            {children}
          </Text>
        )}
    </Box>
  )
}

export default TableCol;

import React from 'react';
import { Box, Button } from 'grommet';

const TableActionsCol = ({ actions }) => {
  return (
    <Box
      direction='row'
      align='center'
      justify='end'
      gap='medium'
    >
      {actions.map((item, index) => (
        <Button
          key={index}
          plain
          label={item.label}
          color='brand'
          onClick={item.action}
        />
      ))}
    </Box>
  )
}

export default TableActionsCol;

const list = [
  { id: '2', name: 'Apartamento' },
  { id: '5', name: 'Área' },
  { id: '6', name: 'Barracão' },
  { id: '7', name: 'Casa' },
  { id: '8', name: 'Chácara' },
  { id: '11', name: 'Fazenda' },
  { id: '12', name: 'Galpão' },
  { id: '14', name: 'Haras' },
  { id: '15', name: 'Ponto' },
  { id: '16', name: 'Prédio' },
  { id: '17', name: 'sala' },
  { id: '18', name: 'Salão' },
  { id: '19', name: 'Sítio' },
  { id: '20', name: 'Terreno' },
  { id: '22', name: 'Kitnet' },
  { id: '23', name: 'Flat' },
  { id: '24', name: 'Cobertura' },
  { id: '25', name: 'Sobrado' },
  { id: '26', name: 'Loja' },
  { id: '27', name: 'Conjunto' },
  { id: '28', name: 'Village' },
  { id: '29', name: 'Laje' },
  { id: '30', name: 'Pousada' },
  { id: '31', name: 'Bangalô' },
  { id: '32', name: 'Loft' },
  { id: '33', name: 'Ilha' },
  { id: '34', name: 'Penthhouse' },
  { id: '35', name: 'Apartamento Duplex' },
  { id: '36', name: 'Resort' },
  { id: '37', name: 'Hotel' },
  { id: '38', name: 'Rancho' },
  { id: '39', name: 'Studio' },
  { id: '40', name: 'Apartamento Triplex' },
  { id: '41', name: 'Edícula' },
  { id: '42', name: 'Box/Garagem' },
  { id: '43', name: 'Pavilhão' },
  { id: '44', name: 'Andar Corporativo' },
  { id: '45', name: 'Apartamento Garden' },
];

export default list;

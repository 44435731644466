import axiosGlobalInstance from './configHttpInstance/http-instance';
import mockChangeMigrationStatus from './fakes/migration-change-status';

const postChangeStatus = ({ migrationId, status }) => {
  const useFake = !!Number(process.env.REACT_APP_MOCK_API);

  if(useFake) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockChangeMigrationStatus), 2000);
    });
  }

  const httpInstance = axiosGlobalInstance();

  return httpInstance({
    method: 'PATCH',
    url: `/migrations/${migrationId}/status`,
    data: { status }
  });
}

export default postChangeStatus;

import React from 'react';
import { Box, Text } from 'grommet';

const Description = ({ title, text, truncateValue }) => {
  return (
    <Box
      direction='column'
      margin={{ vertical: 'small' }}
      fill='horizontal'
    >
      <Text
        size='small'
        color='dark-4'
        textAlign='start'
      >
        {title}
      </Text>

      <Text
        size='medium'
        color='dark-2'
        textAlign='start'
        truncate={truncateValue}
        title={truncateValue ? text : null}
      >
        {text}
      </Text>
    </Box>
  )
}

export default Description;

const userDetail = {
  data: {
    _id: 2,
    nickname: 'Antonio Leme Junior',
    full_name: 'Antonio Leme Junior',
    email: 'antonio.leme@ingaia.com.br',
    creci: 'fst5hn',
    identification_card: '00.009.987-3',
    social_security_number: '584.698.325-29',
    nationality: 'Brasileira',
    created_date: '2021-02-01T10:00:00Z',
    starting_date: '2020-08-19T10:00:00Z',
    exit_date: null,
    born_date: '1987-08-28T10:00:00Z',
    enable: false,
    gender: 'Masculino',
    phone: '(19) 9558-22587',
    cellphone: '(19) 1122-36900',
    radio: '56579-082 55',
    profile: 'Diretor',
  }
}

export default userDetail;

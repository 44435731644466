import React from 'react';
import { ResponsiveContext, Box } from 'grommet';

const PageWrapper = ({ children }) => (
  <ResponsiveContext.Consumer>
    {size => (
      <Box
        direction='row'
        justify='center'
        margin={{ horizontal: 'medium', top: '30px' }}
      >
        <Box
          pad='small'
          width={ size === 'small' || size === 'medium' ? '90vw' : '90vw' }
          gap={ size === 'small' ? 'medium' : 'xsmall'}
          align='center'
        >
          {children}
        </Box>
      </Box>
    )}
  </ResponsiveContext.Consumer>
)

export default PageWrapper;

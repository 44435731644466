import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';

const Badge = ({ text, background = 'dark-5', textColor = 'white' }) => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          background={background}
          round='medium'
          direction='row'
          justify='center'
          pad={{
            horizontal: size === 'small' ? 'small' : 'xsmall',
            vertical: size === 'small' ? 'xxsmall' : 'none',
          }}
        >
          <Text
            title={text}
            truncate
            color={textColor}
            size='xsmall'
          >
            {text}
          </Text>
        </Box>
      )}

    </ResponsiveContext.Consumer>
  )
}

export default Badge;

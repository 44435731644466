import React, { useContext } from 'react';
import { Box, Layer } from 'grommet';

import { userDetail } from '../../../../api';
import { formatDatetime } from '../../../../commons';
import {
  Alert,
  Placeholder,
  Loading,
  Container,
  Title,
  SubTitle,
  Word,
  Wrapper,
  Separator,
} from '../../../../components';

import { AppContext } from '../../../../context';
import { useFetchDetail, useCloseLayer } from '../../../../hooks';

import { layers, messageDictionary } from '../../../../constants';

const UserDetail = () => {
  const { close } = useCloseLayer();
  const { layerVisible } = useContext(AppContext);

  const { loading, detailData, requestError, retryRequest } = useFetchDetail({
    get: userDetail,
    layerType: layers.detail
  });

  if(!layerVisible || layerVisible !== layers.detail)
    return null;

  return (
    <Layer
      responsive={false}
      full='vertical'
      position='left'
      onClickOutside={close}
      onEsc={close}
    >
      <Box
        fill='vertical'
        width='60vw'
        overflow='auto'
        flex
      >
        {requestError ? (
          <Placeholder
            title='Ops.'
            message={messageDictionary.requestFail}
            button={{
              label: 'Tentar novamente',
              action: retryRequest,
            }}
          />
        ) : null}

        {loading && !requestError ? (<Loading />) : null}

        {!loading && !requestError ? (
          <Container>
            <Title value='Detalhes do usuário' />

            <Alert
              alertType='warning'
              message={messageDictionary.warningMigrationUser}
            />

            <Wrapper>
              <Word title='Apelido' text={detailData.nickname} />
              <Word title='Nome Completo' text={detailData.full_name} />
              <Word title='E-mail' text={detailData.email} />
              <Word title='Creci' text={detailData.creci} />
              <Word title='RG' text={detailData.identification_card} />
              <Word title='CPF' text={detailData.social_security_number} />
            </Wrapper>

            <Separator />

            <SubTitle text='Telefones' />

            <Wrapper>
              <Word title='Telefone' text={detailData.phone} />
              <Word title='Celular' text={detailData.cellphone} />
              <Word title='Rádio' text={detailData.radio} />
            </Wrapper>

            <Separator />

            <SubTitle text='Outros detalhes' />

            <Wrapper>
              <Word title='Nacionalidade' text={detailData.nationality} />
              <Word title='Gênero' text={detailData.gender} />
              <Word title='Perfil de acesso' text={detailData.profile} />
              <Word title='Data de cadastro' text={formatDatetime(detailData.created_date)} />
              <Word title='Data de início' text={formatDatetime(detailData.starting_date)} />
              <Word title='Data de saída' text={formatDatetime(detailData.exit_date)} />
              <Word title='Data de nascimento' text={formatDatetime(detailData.born_date)} />
            </Wrapper>
          </Container>
        ) : null}
      </Box>
    </Layer>
  );
}

export default UserDetail;

const meDetail = {
  data: {
    name: 'IQ Imóveis',
    intercom: {
      Website: 'http://trial.imob_3959312773.ingaiasites.com.br/',
      IP: '18.228.6.108',
      name: 'iQ Imóveis',
      user_id: 377364,
      app_id: 'r3d6yia4',
      email: 'info@iqimoveis.com.br',
      company: {
        created_at: 1617580800,
        id: 51163,
        name: '[51163] iQ Imóveis'
      },
      'Agency Status': 'Ativa',
      'Backoffice Link': 'http://backofficev2.valuegaia.com.br/agencies/51163',
      'Agency Id': 51163,
      'Agency Signup': 1617580800,
      'SL Code': 64157,
      'Product Name': 'inGaia Imob',
      'User Signup Date': 1617580800,
      'Imob Login': 'https://ingaia-relacionamento.appspot.com//freshplugs/signin_imob?email=info@iqimoveis.com.br&pwd=N62fAs/ANOCu5Eywno7VrA==',
      'SDR - Team': '-',
      'User Role': 'Diretor',
      'Agency Name': 'iQ Imóveis'
    }
  }
}

export default meDetail;

import { parseDate, isValidDate } from '../../../commons';

const preprocessorSend = (values) => {
  const parsedBornDate = parseDate(values.born_date);

  return {
    _id: values._id,
    nickname: values.nickname,
    full_name: values.full_name,
    email: values.email,
    born_date: isValidDate(parsedBornDate) ? parsedBornDate.toISOString() : null,
    phone: values.phone,
    cellphone: values.cellphone,
    radio: values.radio,
  }
};

export default preprocessorSend;

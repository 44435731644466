import React, { useContext, useEffect, useState} from 'react';
import { Box, Layer, Text, TextInput, Anchor, Select, Button } from 'grommet';
import { AddCircle, Close } from 'grommet-icons';

import { customerDetail, edit } from '../../../../api';

import { Placeholder, Loading, Container, Title, Alert, SubTitle } from '../../../../components';
import { TitleField, TelephoneInput } from '../../../../components/form';

import { AppContext } from '../../../../context';
import { useForm, useFetchDetail, useCloseLayer } from '../../../../hooks';

import { resolveEnum } from '../../../../commons';
import { preprocessorSend, formatEditObject } from '../../utils';

import { layers, entities, gender, mediaType, personType, phoneType, messageDictionary } from '../../../../constants';

const CustomerEdit = () => {
  const { close } = useCloseLayer();
  const { layerVisible, reloadFetch } = useContext(AppContext);

  const { loading, detailData, requestError, retryRequest } = useFetchDetail({
    get: customerDetail,
    layerType: layers.edit
  });

  const { values, errors, pristine, submitting, handleSubmit, changeValues, addOnListValues, removeOnListValues, initialize, resetEvents } = useForm();

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  const [phoneTypeId, setPhoneTypeId] = useState(0);

  useEffect(() => {
    if (!layerVisible || !detailData) return;
  
    initialize(formatEditObject(detailData));
  }, [layerVisible, detailData, initialize]); 

  if(!layerVisible || layerVisible !== layers.edit)
    return null;

  return (
    <Layer
      responsive={false}
      full='vertical'
      position='left'
      onClickOutside={close}
      onEsc={close}
    >
      <Box
        fill='vertical'
        width='60vw'
        overflow='auto'
        flex
      >
        {requestError ? (
          <Placeholder
            title='Ops.'
            message={messageDictionary.requestFail}
            button={{
              label: 'Tentar novamente',
              action: retryRequest,
            }}
          />
        ) : null}

        {loading && !requestError ? (<Loading />) : null}

        {!loading && !requestError ? (
          <Container>
            <Title value='Editar cliente' />


            {/* nome */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box width='large' fill='horizontal'>
                <TitleField text='Nome' required />

                <TextInput
                  maxLength={80}
                  value={values.name ?? ''}
                  name='name'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            {/* midia e genero */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box width='large'>
                <TitleField text='Mídia de origem' required />

                <Select
                  options={mediaType}
                  value={values.customer_media_id?.toString() ?? ''}
                  labelKey='name'
                  valueKey={{
                    key: 'id',
                    reduce: true,
                  }}
                  name='customer_media_id'
                  emptySearchMessage='Selecione uma opção'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>

              <Box width='medium'>
                <TitleField text='Gênero' required />

                <Select
                  options={gender}
                  value={values.gender_id?.toString() ?? ''}
                  labelKey='name'
                  valueKey={{
                    key: 'id',
                    reduce: true,
                  }}
                  name='gender_id'
                  emptySearchMessage='Selecione uma opção'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            {/* midia e genero */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box width='large' fill='horizontal'>
                <TitleField text='Tipo de pessoa' required />

                <Select
                  options={personType}
                  value={values.person_type_id?.toString() ?? ''}
                  labelKey='name'
                  valueKey={{
                    key: 'id',
                    reduce: true,
                  }}
                  name='person_type_id'
                  emptySearchMessage='Selecione uma opção'
                  onChange={({ target }) => changeValues(target.name, target.value)}
                />
              </Box>
            </Box>


            <SubTitle text='Telefone(s)' />

            {/* telefone */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box
                round='xsmall'
                border={{ color: 'light-5' }}
                background='white'
              >
                {/* fields */}
                <Box
                  direction='row'
                  border={{ color: 'light-5', side: 'bottom' }}
                  pad={{
                    vertical: 'xsmall',
                    horizontal: 'xsmall',
                  }}
                >
                  <Box width='large'>
                    <TextInput
                      plain
                      autoComplete='disabled'
                      placeholder='Tipo telefone...'
                      value={resolveEnum(phoneType, phoneTypeId) || ''}
                      suggestions={
                        phoneType.map(({ id, name }) => ({
                          label: (
                            <Box
                              pad='small'
                            >
                              <Text size='medium' color='dark-5'>{name}</Text>
                            </Box>
                          ),
                          value: { id, name }
                        }))
                      }
                      onSuggestionSelect={({ suggestion }) => {
                        const { id } = suggestion.value;

                        setPhoneTypeId(id);
                      }}
                    />
                  </Box>

                  <Box width='large'>
                    <TelephoneInput
                      plain
                      autoComplete='disabled'
                      value={phone}
                      onChange={({ target }) => setPhone(target.value)}
                    />
                  </Box>

                  <Button
                      icon={<AddCircle color='neutral-1' />}
                      disabled={!phoneTypeId || phone.length < 14}
                      title='Adicionar telefone'
                      onClick={() => {
                        addOnListValues(
                          'phones',
                          {
                            phone,
                            phone_type_id: phoneTypeId,
                          }
                        );

                        setPhone('');
                        setPhoneTypeId(0);
                      }}
                    />
                </Box>

                {/* list */}
                {values.phones && values.phones.map((item, index) => (
                    <Box
                      key={index}
                      align='center'
                      direction='row'
                      pad={{
                        horizontal: 'small',
                      }}
                      border={{ color: 'light-5', side: 'bottom' }}
                      justify='between'
                    >
                      <Box width='large'>
                        <Text size='medium' color='dark-5'>
                          {resolveEnum(phoneType, item.phone_type_id)}
                        </Text>
                      </Box>

                      <Box width='large'>
                        <Text size='medium' color='dark-5'>
                          {item.phone}
                        </Text>
                      </Box>

                      <Anchor
                        icon={<Close size='small' />}
                        color='status-critical'
                        title='Remover telefone'
                        onClick={() =>
                          removeOnListValues('phones', item)
                        }
                      />
                    </Box>
                  ))}
              </Box>
            </Box>


            <SubTitle text='E-mail(s)' />

            {/* e-mail */}
            <Box
              direction='row'
              gap='small'
              pad={{ vertical: 'small' }}
            >
              <Box
                round='xsmall'
                border={{ color: 'light-5' }}
                background='white'
              >
                {/* fields */}
                <Box
                  direction='row'
                  border={{ color: 'light-5', side: 'bottom' }}
                  pad={{
                    vertical: 'xsmall',
                    horizontal: 'xsmall',
                  }}
                >
                  <Box width='large'>
                    <TextInput
                      plain
                      autoComplete='disabled'
                      value={email}
                      placeholder='E-mail...'
                      maxLength={100}
                      onChange={({ target }) => setEmail(target.value)}
                    />
                  </Box>

                  <Button
                      icon={<AddCircle color='neutral-1' />}
                      disabled={!email}
                      title='Adicionar e-mail'
                      onClick={() => {
                        addOnListValues(
                          'emails', email,
                        );

                        setEmail('');
                      }}
                    />
                </Box>

                {/* list */}
                {values.emails && values.emails.map((item, index) => (
                    <Box
                      key={index}
                      align='center'
                      direction='row'
                      pad={{
                        horizontal: 'small',
                      }}
                      border={{ color: 'light-5', side: 'bottom' }}
                      justify='between'
                    >
                      <Text size='medium' color='dark-5'>
                        {item}
                      </Text>

                      <Anchor
                        icon={<Close size='small' />}
                        color='status-critical'
                        title='Remover telefone'
                        onClick={() =>
                          removeOnListValues('emails', item)
                        }
                      />
                    </Box>
                  ))}
              </Box>
            </Box>


            {errors && <Alert alertType='error' message={messageDictionary.requestFail} />}

            {/* botões */}
            <Box
              direction='row'
              pad={{ vertical: 'medium' }}
              justify='center'
              align='center'
              gap='small'
            >
              <Box width='xsmall'>
                <Anchor
                  color='dark-3'
                  label='Cancelar'
                  onClick={() => {
                    close();
                    resetEvents();
                  }}
                />
              </Box>

              <Box width='small'>
                <Button
                  color='brand'
                  primary
                  label={!submitting ? 'Atualizar' : 'Atualizando...'}
                  disabled={pristine || submitting}
                  onClick={() =>
                    handleSubmit({
                      resource: edit({ entity: entities.customer, payload: preprocessorSend(values) }),
                      callbackSuccess: () =>
                        new Promise(
                          () => setTimeout(() => {
                            close();
                            reloadFetch();
                          }, 100)
                        )
                    })
                  }
                />
              </Box>
            </Box>

          </Container>
        ) : null}
      </Box>
    </Layer>
  );
}

export default CustomerEdit;

import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import { Login, Logout } from '../../modules/auth/pages';
import { Customers } from '../../modules/customer/pages';
import { ChangeMigrationApproveStatus, ChangeMigrationRefuseStatus, Migrations } from '../../modules/migration/pages';
import { Users } from '../../modules/user/pages';
import { Properties } from '../../modules/property/pages';
import { Enterprises } from '../../modules/enterprise/pages';

import { AppContext } from '../../context';

import urlRoutes from '../../config/url-routes';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authenticated } = useContext(AppContext);

  return (
    <Route {...rest} render={(props) => (
      authenticated
        ? <Component {...props} />
        : <Redirect to={{ pathname: urlRoutes.login }} />
    )} />
  );
}

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route exact path={urlRoutes.login} component={Login} />
        <Route exact path={urlRoutes.logout} component={Logout} />

        <PrivateRoute path={urlRoutes.usersUrl} component={Users} />
        <PrivateRoute path={urlRoutes.propertiesUrl} component={Properties} />
        <PrivateRoute path={urlRoutes.customersUrl} component={Customers} />
        <PrivateRoute path={urlRoutes.enterprisesUrl} component={Enterprises} />
        <PrivateRoute path={urlRoutes.changeMigrationApproveStatusUrl} component={ChangeMigrationApproveStatus} />
        <PrivateRoute path={urlRoutes.changeMigrationRefuseStatusUrl} component={ChangeMigrationRefuseStatus} />

        <PrivateRoute path={urlRoutes.migrationsUrl} component={Migrations} />
      </Switch>
    </Router>
  )
}

import React from 'react';
import { Box, Text } from 'grommet';

const Word = ({ title, text, truncateValue }) => (
  <Box
    direction='column'
    height={{ min: 'xxsmall' }}
  >
    <Text
      size='small'
      color='dark-4'
      textAlign='start'
    >
      {title}
    </Text>

    <Text
      size='medium'
      color='dark-2'
      textAlign='start'
      truncate={truncateValue}
      title={truncateValue ? text : null}
    >
      {text}
    </Text>
  </Box>
)

export default Word;

import React from 'react';
import { Box, Spinner } from 'grommet';

const Loading = () => (
  <Box
    align='center'
    justify='center'
    height='90vh'
  >
    <Spinner
      size='medium'
      border={[
        { side: 'all', color: 'light-3', size: 'small' },
        { side: 'top', color: 'brand', size: 'small' },
      ]}
    />
  </Box>
)

export default Loading;

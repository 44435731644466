const mask = (value, pattern) => {
  if(!value) return value;

  let i = 0;

  const v = value.toString();

  return pattern.replace(/#/g, () => v[i++] || '')
}

export default mask;
